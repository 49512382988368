import $api from '../api'

class ApiKeyApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      manageApiKey: (id) => `/admin/api-key/${id}`,
      createApiKey: '/admin/api-key',
      getApiKeys: '/admin/api-keys',
    }
    this.#abortControllers = {}
  }

  updateApiKey = async (id, data) => {
    const response = await this.#api.put(this.#endPoints.manageApiKey(id), data)
    return response
  }

  deleteApiKey = async (id) => {
    const response = await this.#api.delete(this.#endPoints.manageApiKey(id))
    return response
  }

  createApiKey = async (data) => {
    const response = await this.#api.post(this.#endPoints.createApiKey, data)
    return response
  }

  getApiKeys = async (params, code = 'getApiKeys') => {
    this.#abortControllers[code]?.abort()
    this.#abortControllers[code] = new AbortController()
    const response = await this.#api.get(this.#endPoints.getApiKeys, {
      params,
      signal: this.#abortControllers[code]?.signal,
    })
    return response
  }
}

const apiKeyService = new ApiKeyApiService({ api: $api })

export default apiKeyService
