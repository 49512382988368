import { banDevice } from '../devices.actions'

export default (builder) => {
  builder
    .addCase(banDevice.pending, (state) => {
      state.manageDeviceStatus.isLoading = true
    })
    .addCase(banDevice.fulfilled, (state, action) => {
      state.manageDeviceStatus.error = null
      state.manageDeviceStatus.isLoading = false
      if (state.selectedDevice.id === action.payload.id) {
        state.selectedDevice.deviceGlobalStatus = action.payload.response.data?.deviceGlobalStatus
      }
      const updateDevice = state.devices.find((item) => item.id === action.payload.id)
      if (updateDevice) {
        updateDevice.deviceGlobalStatus = action.payload.response.data?.deviceGlobalStatus
      }
    })
    .addCase(banDevice.rejected, (state, action) => {
      state.manageDeviceStatus.isLoading = false
      state.manageDeviceStatus.error = action.payload
    })
}
