import React from 'react'
import { Button } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'

const UIButtonMediumDanger = ({ children, sx, ...props }) => {
  return (
    <Button
      sx={{
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 700,
        py: '10px',
        background: COLOR.RED_ERROR,
        color: COLOR.OFF_WHITE,
        transition: '0.25s all ease-in-out',
        '&:hover': {
          color: COLOR.WHITE,
          background: COLOR.RED_DARK,
          transition: '0.25s all ease-in-out',
        },
        '&.Mui-disabled': {
          background: COLOR.GRAY_LIGHT,
          color: COLOR.WHITE,
          transition: '0.25s all ease-in-out',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default UIButtonMediumDanger
