import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { generateReactKey } from '../../../../utils/helpers/reactKey.helper'
import { COLOR } from '../../../../utils/constants/color.constants'

const FormSelectIsolated = ({ label, id, list, labelStyles, ...props }) => {
  return (
    <FormControl fullWidth size="small" sx={{ position: 'relative' }}>
      <InputLabel
        id={`label-${id}`}
        sx={{
          background: COLOR.GRAY_EXTRA_LIGHT,
          px: '4px',
          ...labelStyles,
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`label-${id}`}
        id={id}
        sx={{
          boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        {...props}
      >
        {list.map((item) => {
          return (
            <MenuItem key={generateReactKey()} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default FormSelectIsolated
