import React from 'react'
import {
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
} from '@mui/material'
import { useField } from 'formik'
import UITextBodyMedium from '../../../../ui/UIText/UITextBodyMedium'
import { COLOR } from '../../../../utils/constants/color.constants'

const FormInput = ({ label, id, required, suffix, regExp, validateOnChange, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers

  const handleChange = ({ target }) => {
    if (regExp) {
      setValue(target.value.replace(regExp, ''))
      return
    }
    setValue(target.value)
  }

  return (
    <FormControl
      fullWidth
      size="small"
      error={meta.error && meta.touched}
      sx={{ position: 'relative' }}
    >
      <InputLabel htmlFor={id}>
        {label} {required ? <span style={{ color: COLOR.RED_ERROR }}>*</span> : ''}
      </InputLabel>
      <OutlinedInput
        id={id}
        label={label}
        onChange={handleChange}
        name={field.name}
        value={field.value}
        sx={{
          boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        endAdornment={
          <InputAdornment position="end">
            <UITextBodyMedium sx={{ color: COLOR.GRAY_MEDIUM_LIGHT }} text={suffix} />
          </InputAdornment>
        }
        {...props}
      />
      {meta.error && (meta.touched || validateOnChange) ? (
        <FormHelperText sx={{ mt: '2px', position: 'absolute', left: 0, top: '100%' }}>
          <UITextBodyMedium
            component="span"
            sx={{ color: COLOR.RED_ERROR, fontSize: '12px' }}
            text={meta.error}
          />
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default FormInput
