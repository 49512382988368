import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import {
  setFilterGlobalStatus,
  setFilterCurrentStatus,
  setFiltersCollapsed,
  setSearchLine,
} from '../../../store/slices/devices/devices.reducer'
import UIIconButtonFilter from '../../../ui/UIButton/UIIconButton/UIIconButtonFilter'
import Search from '../../../components/Search/Search'
import AddRecorder from './AddRecorder'
import FormSelectIsolated from '../../../components/Forms/FormComponents/FormSelect/FormSelectIsolated'
import { currentStatusTypes, globalStatusTypes } from '../../../utils/constants/enums.constants'
import { getTypesList } from '../../../utils/helpers/enums.helper'
import { COLOR } from '../../../utils/constants/color.constants'

const RecordersHeader = () => {
  const globalStatus = useSelector((state) => state.devices.filters.globalStatus)
  const currentStatus = useSelector((state) => state.devices.filters.currentStatus)
  const filtersCollapsed = useSelector((state) => state.devices.filters.collapsed)
  const searchLine = useSelector((state) => state.devices.searchLine)
  const dispatch = useDispatch()

  return (
    <Box
      className={!filtersCollapsed ? 'expanded' : ''}
      sx={{
        position: 'relative',
        borderBottom: '1px solid',
        borderColor: COLOR.GRAY_LIGHT,
        pb: 0,
        transition: '0.25s all ease-in-out',
        '&.expanded': {
          pb: '71px',
          transition: '0.25s all ease-in-out',
        },
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Search value={searchLine} onChange={(e) => dispatch(setSearchLine(e.target.value))} />
        <Box
          sx={{
            px: '2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UIIconButtonFilter
            open={!filtersCollapsed}
            onClick={() => dispatch(setFiltersCollapsed(!filtersCollapsed))}
          />
        </Box>
        <AddRecorder />
      </Box>
      <Box
        className={!filtersCollapsed ? 'active' : ''}
        sx={{
          position: 'absolute',
          top: '57px',
          left: 0,
          width: '100%',
          p: '15px',
          display: 'flex',
          borderTop: '1px solid',
          borderColor: COLOR.GRAY_LIGHT,
          transform: 'scaleY(0.2)',
          opacity: 0,
          transformOrigin: '50% 0%',
          transition: '0.25s all ease-in-out',
          '&.active': {
            opacity: 1,
            transform: 'scale(1)',
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        <Grid container spacing="20px">
          <Grid item xs={12} sm={6}>
            <FormSelectIsolated
              label="Global status"
              list={getTypesList(globalStatusTypes, true)}
              labelStyles={{
                background: COLOR.WHITE,
              }}
              value={globalStatus}
              onChange={(e) => dispatch(setFilterGlobalStatus(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectIsolated
              label="Current status"
              list={getTypesList(currentStatusTypes, true)}
              labelStyles={{
                background: COLOR.WHITE,
              }}
              value={currentStatus}
              onChange={(e) => dispatch(setFilterCurrentStatus(e.target.value))}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default RecordersHeader
