import { getCustomers } from '../customers.actions'

export default (builder) => {
  builder
    .addCase(getCustomers.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getCustomers.fulfilled, (state, action) => {
      state.customers = action.payload
      state.isLoading = false
    })
    .addCase(getCustomers.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
