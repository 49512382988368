import React from 'react'
import { Box } from '@mui/material'
import Logo from '../../assets/img/logo.svg'

const PublicHeader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        py: '16px',
        px: '38px',
      }}
    >
      <Box sx={{ width: '175px' }}>
        <img src={Logo} alt="IOTI logo" style={{ width: '100%' }} />
      </Box>
    </Box>
  )
}

export default PublicHeader
