import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import './assets/fonts/stylesheet.css'
import AppRouter from './router/AppRouter'
import { getUserInfo } from './store/slices/auth/auth.actions'
import { setAuthChecked } from './store/slices/auth/auth.reducer'
import { getToken } from './utils/helpers/auth.helper'
import { ROLES } from './utils/constants/global.constants'
import useWebSocket from './hooks/useWebSocket'
import UIFullScreenPreloader from './ui/UIPreloader/UIFullScreenPreloader'
import useGeneralSocket from './hooks/useGeneralSocket'

function App() {
  const socketClient = useSelector((state) => state.auth.socketClient)
  const role = useSelector((state) => state.auth.user?.role)
  const authChecked = useSelector((state) => state.auth.authChecked)
  const dispatch = useDispatch()
  const { setStartConnection } = useWebSocket()
  const { handleSubscribeToGeneralEvents } = useGeneralSocket()

  const checkSocketConnection =
    (socketClient?.connected && role === ROLES.ADMIN) || role !== ROLES.ADMIN

  useEffect(() => {
    if (socketClient && socketClient?.connected) {
      handleSubscribeToGeneralEvents()
    }
  }, [socketClient])

  useEffect(() => {
    if (role && role === ROLES.ADMIN) {
      setStartConnection(true)
    } else {
      setStartConnection(false)
    }
  }, [role])

  useEffect(() => {
    if (getToken()) {
      dispatch(getUserInfo())
    } else {
      dispatch(setAuthChecked(true))
    }
  }, [])

  return authChecked && checkSocketConnection ? (
    <>
      <AppRouter />
      <ToastContainer />
    </>
  ) : (
    <UIFullScreenPreloader />
  )
}

export default App
