import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, IconButton, Stack } from '@mui/material'
import { ArrowBack, Info, PlayCircle } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  resetTriggersData,
  setSegmentGroupVideo,
} from '../../store/slices/triggers/triggers.reducer'
import UITextHeadlineLarge from '../../ui/UIText/UITextHeadlineLarge'
import UIPaper from '../../ui/UIPaper/UIPaper'
import UITable from '../../ui/UITable/UITable'
import UIOverlayPreloader from '../../ui/UIPreloader/UIOverlayPreloader'
import { getSegmentGroupSegmentsExtendedTableData } from '../../utils/helpers/tables.helper'
import { segmentGroupSegmentsExtendedTableHead } from '../../utils/constants/tables.constants'
import { COLOR } from '../../utils/constants/color.constants'
import { getSegmentGroup, getSegmentVideo } from '../../store/slices/triggers/triggers.actions'
import SegmentInfoModal from '../../components/Modals/SegmentInfoModal/SegmentInfoModal'
import SegmentGroupPlayer from './SegmentGroupPlayer'

const SegmentGroup = () => {
  const selectedSegmentGroup = useSelector((state) => state.triggers.selectedSegmentGroup.data)
  const { loading, segmentId } = useSelector((state) => state.triggers.video)
  const isLoading = useSelector((state) => state.triggers.selectedSegmentGroup.isLoading)
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const [infoModal, setInfoModal] = useState(false)
  const [infoModalData, setInfoModalData] = useState(null)

  const renderActionItems = (data) => {
    return (
      <Stack direction="row" gap="5px" sx={{ pr: '20px' }} alignItems="center">
        <IconButton
          onClick={() => {
            setInfoModalData(data?.initialData)
            setInfoModal(true)
          }}
        >
          <Info sx={{ fill: COLOR.GRAY_MEDIUM }} />
        </IconButton>
        <IconButton
          sx={{
            width: '40px',
            height: '40px',
          }}
          onClick={() => {
            dispatch(setSegmentGroupVideo(data?.initialData?.id))
            dispatch(getSegmentVideo({ segmentId: data?.initialData?.id }))
          }}
        >
          {loading && segmentId === data?.initialData?.id ? (
            <CircularProgress size="20px" sx={{ color: COLOR.WHITE }} />
          ) : (
            <PlayCircle sx={{ fill: COLOR.GRAY_DARK }} />
          )}
        </IconButton>
      </Stack>
    )
  }

  useEffect(() => {
    dispatch(getSegmentGroup({ segmentGroupId: params.id }))
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetTriggersData())
    }
  }, [])

  return (
    <Box
      sx={{
        flex: 2,
        p: '30px',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Stack gap="30px">
        <Stack direction="row" gap="15px">
          <IconButton onClick={() => navigate('/history')}>
            <ArrowBack />
          </IconButton>
          <UITextHeadlineLarge text={`Triggers history: ${selectedSegmentGroup?.name}`} />
        </Stack>
        <UIPaper>
          <Stack gap="20px">
            <Stack direction="row" justifyContent="center">
              <SegmentGroupPlayer />
            </Stack>
            <Box
              sx={{
                borderRadius: '6px',
                overflow: 'hidden',
                position: 'relative',
                maxHeight: '39vh',
                overflowY: 'auto',
              }}
            >
              {isLoading ? <UIOverlayPreloader /> : null}
              <UITable
                head={segmentGroupSegmentsExtendedTableHead}
                data={getSegmentGroupSegmentsExtendedTableData(
                  selectedSegmentGroup?.segments ?? [],
                )}
                emptyText="No segments found"
                variant="rounded"
                renderActionItems={renderActionItems}
              />
            </Box>
          </Stack>
        </UIPaper>
      </Stack>
      <SegmentInfoModal open={infoModal} setOpen={setInfoModal} data={infoModalData} />
    </Box>
  )
}

export default SegmentGroup
