import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import triggersApiService from '../../../api/triggersApiService/triggersApiService'
import segmentsApiService from '../../../api/segmentsApiService/segmentsApiService'

export const getTriggers = createAsyncThunk(
  'triggers/getTriggers',
  async ({ deviceId }, { rejectWithValue, getState }) => {
    try {
      const { page, size, sort } = getState().triggers.pagination
      const params = {
        page,
        size,
      }
      if (sort !== '') params.sort = sort
      if (deviceId) params.deviceId = deviceId
      const response = await triggersApiService.getTriggers(params)
      return { data: response.data, deviceId }
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const getSegmentGroup = createAsyncThunk(
  'triggers/getSegmentGroup',
  async ({ segmentGroupId }, { rejectWithValue }) => {
    try {
      const response = await segmentsApiService.getSegmentGroup(segmentGroupId)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const getSegmentVideo = createAsyncThunk(
  'triggers/getSegmentVideo',
  async ({ segmentId }, { rejectWithValue }) => {
    try {
      const response = await segmentsApiService.getVideoLink(segmentId)
      return response.data
    } catch (e) {
      if (!e.response) toast.error('Unknown error')
      return rejectWithValue(e.response.data.message)
    }
  },
)
