import { toast } from 'react-toastify'
import { createAsyncThunk } from '@reduxjs/toolkit'
import segmentsApiService from '../../../api/segmentsApiService/segmentsApiService'

export const getSavedSegments = createAsyncThunk(
  'segments/getSavedSegments',
  async ({ deviceId }, { rejectWithValue, getState }) => {
    try {
      const { page, size } = getState().segments.savedSegmentsPagination
      const params = {
        page,
        limit: size,
      }
      const response = await segmentsApiService.getSegments(deviceId, params)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const uploadSegments = createAsyncThunk(
  'segments/uploadSegments',
  async ({ deviceId, data }, { rejectWithValue }) => {
    try {
      const response = await segmentsApiService.uploadSegments(deviceId, data)
      return response
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const getVideoLink = createAsyncThunk(
  'segments/getVideoLink',
  async ({ segmentId }, { rejectWithValue }) => {
    try {
      const response = await segmentsApiService.getVideoLink(segmentId)
      return response.data
    } catch (e) {
      if (!e.response) toast.error('Unknown error')
      return rejectWithValue(e.response.data.message)
    }
  },
)
