import React from 'react'
import { Form, Formik } from 'formik'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addCustomer, getCustomers } from '../../../../store/slices/customers/customers.actions'
import FormInput from '../../FormComponents/FormInput/FormInput'
import UITextBodyMedium from '../../../../ui/UIText/UITextBodyMedium'
import { COLOR } from '../../../../utils/constants/color.constants'
import UIButtonMedium from '../../../../ui/UIButton/UIButtonMedium'
import UIButtonMediumDanger from '../../../../ui/UIButton/UIButtomMediumDanger'
import { addCustomerValidationSchema } from './addCustomerForm.constants'

const AddCustomerForm = ({ onClose }) => {
  const error = useSelector((state) => state.customers.errorAddCustomer)
  const isLoading = useSelector((state) => state.customers.isLoadingAddCustomer)
  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    dispatch(addCustomer(values))
      .unwrap()
      .then(() => {
        dispatch(getCustomers())
        onClose()
      })
      .catch(() => {
        toast.error(error)
      })
  }

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={addCustomerValidationSchema}
    >
      {(props) => (
        <Form>
          <Stack gap="30px">
            <FormInput
              type="text"
              id="name"
              label="Company name"
              name="name"
              disabled={isLoading}
            />
            {error && error !== '' ? (
              <UITextBodyMedium text={error} sx={{ color: COLOR.RED_ERROR }} />
            ) : null}
            <Stack direction="row" gap="10px">
              <UIButtonMediumDanger onClick={onClose} fullWidth>
                Cancel
              </UIButtonMediumDanger>
              <UIButtonMedium
                fullWidth
                type="submit"
                disabled={isLoading || (props.touched && !props.isValid)}
              >
                Add
              </UIButtonMedium>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default AddCustomerForm
