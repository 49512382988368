import React from 'react'
import { Box } from '@mui/material'
import { generateReactKey } from '../../utils/helpers/reactKey.helper'
import { COLOR } from '../../utils/constants/color.constants'
import UITextLabelLarge from '../UIText/UITextLabelLarge'

const UITableDoubleColor = ({ data, sx, ...props }) => {
  return (
    <Box
      sx={{
        borderRadius: '6px',
        overflow: 'hidden',
        ...sx,
      }}
      {...props}
    >
      {data.map((item, index) => {
        return (
          <Box
            key={generateReactKey()}
            sx={{
              display: 'flex',
              height: '40px',
              background: index % 2 ? COLOR.WHITE : COLOR.GRAY_EXTRA_LIGHT,
              borderRadius: '6px',
              px: '15px',
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <UITextLabelLarge text={item?.title} sx={{ fontWeight: 400, opacity: 0.7 }} />
            </Box>
            <Box
              sx={{
                flex: 2,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <UITextLabelLarge text={item?.value} />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default UITableDoubleColor
