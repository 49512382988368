import SockJS from 'sockjs-client'
import { over } from 'stompjs'
import $api, { BASE_URL } from '../api'
import { getToken } from '../../utils/helpers/auth.helper'

class WebSocketApiService {
  #api

  #endPoints

  #baseUrl

  #client

  #deviceClient

  constructor({ api, baseUrl }) {
    this.#api = api
    this.#baseUrl = baseUrl
    this.#endPoints = {
      webSocket: 'ws',
      commands: {
        startSendingSegments: (id) => `device/${id}/command/start-sending-segments-data`,
        uploadSegment: (id) => `device/${id}/command/upload-segment`,
      },
    }
    this.#client = null
  }

  startConnection = (listener, errorListener) => {
    const Sock = new SockJS(`${this.#baseUrl}${this.#endPoints.webSocket}`)
    const stompClient = over(Sock)
    stompClient.connect(
      {
        Authorization: `Bearer ${getToken()}`,
      },
      listener,
      errorListener,
    )
    this.#client = stompClient
  }

  disconnect = () => {
    if (this.#client) {
      this.#client.disconnect()
    }
  }

  subscribeToDeviceSegments = (deviceId, listener, errorListener) => {
    this.#client.subscribe(`/topic/segments/${deviceId}`, listener, {
      Authorization: `Bearer ${getToken()}`,
    })
  }

  subscribeToDeviceCommands = (deviceId, listener, errorListener) => {
    this.#client.subscribe(`/topic/commands/${deviceId}`, listener, errorListener)
  }

  startSendingSegments = async (deviceId) => {
    const response = await this.#api.post(this.#endPoints.commands.startSendingSegments(deviceId))
    return response
  }

  test = (deviceId, listener) => {
    this.#client.subscribe(`/app/segments/${deviceId}`, listener, {})
  }
}

const webSocketApiService = new WebSocketApiService({
  api: $api,
  baseUrl: BASE_URL,
})

export default webSocketApiService
