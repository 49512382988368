import React from 'react'
import { Box, IconButton, CircularProgress } from '@mui/material'
import { Info, PlayCircle } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { getVideoLink } from '../../../../store/slices/segments/segments.actions'
import { COLOR } from '../../../../utils/constants/color.constants'
import { setVideoSegmentId } from '../../../../store/slices/segments/segments.reducer'

const SegmentsSideTableActions = ({ data, setSegmentInfoModal, setSegmentInfoModalData }) => {
  const dispatch = useDispatch()
  const { loading, segmentId } = useSelector((state) => state.segments.video)

  const handleInfoAction = () => {
    setSegmentInfoModal(true)
    setSegmentInfoModalData(data?.initialData)
  }

  const handlePlayAction = () => {
    dispatch(setVideoSegmentId(data?.initialData?.id))
    dispatch(getVideoLink({ segmentId: data?.initialData?.id }))
  }

  return (
    <Box component="span" sx={{ display: 'flex', justifyContent: 'flex-end', pr: '20px' }}>
      <IconButton onClick={() => handleInfoAction()}>
        <Info sx={{ fill: COLOR.GRAY_MEDIUM }} />
      </IconButton>
      <IconButton
        sx={{
          width: '40px',
          height: '40px',
        }}
        onClick={() => handlePlayAction()}
      >
        {loading && segmentId === data?.initialData?.id ? (
          <CircularProgress size="20px" sx={{ color: COLOR.WHITE }} />
        ) : (
          <PlayCircle sx={{ fill: COLOR.GRAY_DARK }} />
        )}
      </IconButton>
    </Box>
  )
}

export default SegmentsSideTableActions
