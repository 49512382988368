import html2canvas from 'html2canvas'

export const downloadQRCode = (qrCodeElement) => {
  if (qrCodeElement) {
    html2canvas(qrCodeElement).then((canvas) => {
      const link = document.createElement('a')
      link.href = canvas.toDataURL('image/png')
      link.download = 'qrcode.png'
      link.click()
    })
  }
}
