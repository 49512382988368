import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { resetAddDeviceData } from '../../../store/slices/devices/devices.reducer'
import UIModal from '../../../ui/UIModal/UIModal'
import AddDeviceQRScan from './AddDeviceQRScan'
import RegisterDeviceForm from '../../Forms/FormTemplates/RegisterDeviceForm/RegisterDeviceForm'

const AddDeviceModal = ({ open, setOpen }) => {
  const registerToken = useSelector((state) => state.devices.addDevice.token)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) {
      dispatch(resetAddDeviceData())
    }
  }, [open])

  return (
    <UIModal open={open} setOpen={setOpen} wrapperStyles={{ width: '400px' }}>
      {registerToken ? (
        <AddDeviceQRScan setOpen={setOpen} />
      ) : (
        <RegisterDeviceForm onClose={() => setOpen(false)} />
      )}
    </UIModal>
  )
}

export default AddDeviceModal
