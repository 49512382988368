import React from 'react'
import { Grid, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import FormInput from '../../../FormComponents/FormInput/FormInput'
import FormSelect from '../../../FormComponents/FormSelect/FormSelect'
import { getTypesList } from '../../../../../utils/helpers/enums.helper'
import {
  recordingModeTypes,
  recordingSourceTypes,
  resolutionTypes,
  uploadModeTypes,
} from '../../../../../utils/constants/enums.constants'
import FormSwitch from '../../../FormComponents/FormSwitch/FormSwitch'
import UITextTitleMedium from '../../../../../ui/UIText/UITextTitleMedium'
import UITextBodyLarge from '../../../../../ui/UIText/UITextBodyLarge'

const EditDeviceFormMainConfiguration = () => {
  const isLoading = useSelector((state) => state.devices.editDevice.isLoading)

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormInput
          type="text"
          id="name"
          label="Recorder name"
          name="name"
          disabled={isLoading}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <UITextBodyLarge text="Wi-Fi only" />
          <FormSwitch id="wifiOnly" name="wifiOnly" disabled={isLoading} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSelect
          id="uploadMode"
          label="Upload mode"
          name="uploadMode"
          disabled={isLoading}
          required
          list={getTypesList(uploadModeTypes)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSelect
          id="recordingSource"
          label="Recording source"
          name="recordingSource"
          disabled={isLoading}
          required
          list={getTypesList(recordingSourceTypes)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSelect
          id="recordingResolution"
          label="Recording resolution"
          name="recordingResolution"
          disabled={isLoading}
          required
          list={getTypesList(resolutionTypes)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSelect
          id="recordingMode"
          label="Recording mode"
          name="recordingMode"
          disabled={isLoading}
          required
          list={getTypesList(recordingModeTypes)}
        />
      </Grid>
    </>
  )
}

export default EditDeviceFormMainConfiguration
