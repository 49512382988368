import { createSlice } from '@reduxjs/toolkit'
import signInBuilder from './builders/signIn.builder'
import { getToken, removeRefreshToken, removeToken } from '../../../utils/helpers/auth.helper'

const initialState = {
  authChecked: false,
  isAuthenticated: false,
  user: null,
  isLoading: false,
  error: '',
  userInfoError: '',
  socketClient: null,
}

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    checkAuth: (state) => {
      const token = getToken()
      if (token) {
        state.isAuthenticated = true
      }
      state.authChecked = true
    },
    setAuthChecked: (state, action) => {
      state.authChecked = action.payload
    },
    setSocketClient: (state, action) => {
      state.socketClient = action.payload
    },
    logOut: (state) => {
      state.user = null
      state.isAuthenticated = false
      removeToken()
      removeRefreshToken()
    },
  },
  extraReducers: (builder) => {
    signInBuilder(builder)
  },
})

export const { checkAuth, logOut, setAuthChecked, setSocketClient } = authSlice.actions

export default authSlice.reducer
