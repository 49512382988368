import React, { useEffect, useState } from 'react'
import { Grid, Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import UIButtonMediumDanger from '../../../../ui/UIButton/UIButtomMediumDanger'
import UIButtonMedium from '../../../../ui/UIButton/UIButtonMedium'
import { editDeviceValidationSchema } from './editDeviceForm.constants'
import { editDevice } from '../../../../store/slices/devices/devices.actions'
import UITabs from '../../../../ui/UITabs/UITabs'
import UITab from '../../../../ui/UITabs/UITab'
import EditDeviceFormMainConfiguration from './EditDeviceFormSections/EditDeviceFormMainConfiguration'
import EditDeviceFormTriggers from './EditDeviceFormSections/EditDeviceFormTriggers'

const EditDeviceForm = ({ onClose }) => {
  const isLoading = useSelector((state) => state.devices.editDevice.isLoading)
  const { deviceConfiguration, id, name } = useSelector((state) => state.devices.selectedDevice)
  const dispatch = useDispatch()

  const [tab, setTab] = useState(0)

  const handleSubmit = async (values) => {
    const {
      uploadMode,
      recordingSource,
      recordingMode,
      recordingResolution,
      minutesBeforeTrigger,
      minutesAfterTrigger,
      accelerationValue,
      impactTriggerEnabled,
      wifiOnly,
    } = values
    const response = await dispatch(
      editDevice({
        deviceId: id,
        name: values.name,
        configuration: {
          uploadMode,
          recordingSource,
          recordingMode,
          recordingResolution,
          wifiOnly,
        },
        uploadTime: {
          minutesBeforeTrigger,
          minutesAfterTrigger,
        },
        acceleration: {
          isEnabled: impactTriggerEnabled,
          accelerationValue,
        },
      }),
    )
    if (editDevice.fulfilled.match(response)) {
      onClose()
    }
  }

  return (
    <Formik
      initialValues={{
        name: name ?? '',
        ...deviceConfiguration,
        accelerationValue:
          deviceConfiguration?.uploadTrigger?.acceleration?.accelerationValue ?? '',
        minutesAfterTrigger:
          deviceConfiguration?.uploadTrigger?.uploadTime?.minutesAfterTrigger ?? '',
        minutesBeforeTrigger:
          deviceConfiguration?.uploadTrigger?.uploadTime?.minutesBeforeTrigger ?? '',
        impactTriggerEnabled: deviceConfiguration?.uploadTrigger?.acceleration?.isEnabled ?? false,
      }}
      onSubmit={handleSubmit}
      validationSchema={editDeviceValidationSchema}
      validateOnBlur
    >
      {(props) => (
        <Form>
          <Stack gap="20px">
            <UITabs value={tab} onChange={(_, value) => setTab(value)}>
              <UITab label="Main configuration" />
              <UITab label="Triggers" />
            </UITabs>
            <Grid container spacing="30px">
              {tab === 0 ? <EditDeviceFormMainConfiguration /> : null}
              {tab === 1 ? <EditDeviceFormTriggers /> : null}
              <Grid item xs={12}>
                <Stack direction="row" gap="10px" sx={{ mt: '10px' }}>
                  <UIButtonMediumDanger onClick={onClose} fullWidth>
                    Cancel
                  </UIButtonMediumDanger>
                  <UIButtonMedium
                    fullWidth
                    type="submit"
                    disabled={
                      isLoading || (props.touched && !props.isValid && props.submitCount !== 0)
                    }
                  >
                    Update
                  </UIButtonMedium>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default EditDeviceForm
