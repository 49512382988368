import $api from '../api'

class CustomersApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      customers: '/customers',
      customerById: (id) => `/customer/${id}/users`,
      editAdmin: (id) => `/admin/user/${id}`,
      addAdminToCustomer: (id) => `/customer/${id}/admin/users`,
      recoverCustomer: (id) => `/customer/${id}/recover`,
      deleteCustomer: (id) => `/customers/${id}`,
    }
  }

  getCustomers = async () => {
    const response = await this.#api.get(this.#endPoints.customers)
    return response
  }

  getCustomer = async (id) => {
    const response = await this.#api.get(this.#endPoints.customerById(id))
    return response
  }

  addCustomer = async ({ name }) => {
    const response = await this.#api.post(this.#endPoints.customers, { name })
    return response
  }

  addAdminToCustomer = async ({ id, data }) => {
    const response = await this.#api.post(this.#endPoints.addAdminToCustomer(id), data)
    return response
  }

  editAdmin = async ({ userId, data }) => {
    const response = await this.#api.put(this.#endPoints.editAdmin(userId), data)
    return response
  }

  deleteCustomer = async (id) => {
    const response = await this.#api.delete(this.#endPoints.deleteCustomer(id))
    return response
  }

  recoverCustomer = async (id) => {
    const response = await this.#api.post(this.#endPoints.recoverCustomer(id), {})
    return response
  }
}

const customersApiService = new CustomersApiService({ api: $api })

export default customersApiService
