import React from 'react'
import { IconButton } from '@mui/material'
import { COLOR } from '../../../utils/constants/color.constants'
import UITooltip from '../../UITooltip/UITooltip'
import { UISize } from '../../../utils/constants/ui.constants'

const UIIconButton = ({
  children,
  sx,
  accentColor = COLOR.ORANGE,
  size = UISize.md,
  text,
  ...props
}) => {
  return (
    <UITooltip text={text}>
      <IconButton
        sx={{
          '& svg': {
            fill: COLOR.GRAY_MEDIUM,
            fontSize: size === UISize.sm ? '16px' : '20px',
            transition: '0.25s all ease-in-out',
          },
          '&:hover': {
            '& svg': {
              fill: accentColor,
              transition: '0.25s all ease-in-out',
            },
          },
          '&.active': {
            '& svg': {
              fill: accentColor,
              transition: '0.25s all ease-in-out',
            },
          },
          ...sx,
        }}
        {...props}
      >
        {children}
      </IconButton>
    </UITooltip>
  )
}

export default UIIconButton
