import { getSavedSegments } from '../segments.actions'

export default (builder) => {
  builder
    .addCase(getSavedSegments.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getSavedSegments.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.savedSegments = action.payload?.content
      state.savedSegmentsPagination.total = action.payload?.totalElements
    })
    .addCase(getSavedSegments.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
