import React from 'react'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import UITextDisplayLarge from '../../ui/UIText/UITextDisplayLarge'
import SignInForm from '../../components/Forms/FormTemplates/SignInForm/SignInForm'

const Auth = () => {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 98px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          mt: '-100px',
          width: '450px',
          borderRadius: '6px',
          p: '40px',
          boxShadow: '0 0 18px 0 rgba(0,0,0,.1)',
        }}
      >
        <SignInForm />
      </Box>
    </Box>
  )
}

export default Auth
