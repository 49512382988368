import React from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PublicHeader from './PublicHeader'
import Sidebar from './Sidebar/Sidebar'

const Layout = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  return (
    <Box
      sx={{
        overflow: 'hidden',
        width: '100vw',
        height: '100vh',
      }}
    >
      {isAuthenticated ? (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Sidebar />
          <Box sx={{ flex: 1 }}>
            <Outlet />
          </Box>
        </Box>
      ) : (
        <Box>
          <PublicHeader />
          <Outlet />
        </Box>
      )}
    </Box>
  )
}

export default Layout
