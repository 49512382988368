import { useEffect, useState } from 'react'
import SockJS from 'sockjs-client'
import { over } from 'stompjs'
import { useSelector, useDispatch } from 'react-redux'
import { setSocketClient } from '../store/slices/auth/auth.reducer'
import { BASE_URL } from '../api/api'
import { getToken } from '../utils/helpers/auth.helper'

const UseWebSocket = () => {
  const socketClient = useSelector((state) => state.auth.socketClient)
  const dispatch = useDispatch()

  const [startConnection, setStartConnection] = useState(false)

  const handleStartConnection = () => {
    const Sock = new SockJS(`${BASE_URL}ws`)
    const stompClient = over(Sock)
    stompClient.connect({ Authorization: `Bearer ${getToken()}` }, () => {
      dispatch(setSocketClient(stompClient))
    })
  }

  useEffect(() => {
    if (startConnection) {
      handleStartConnection()
    } else if (socketClient) {
      socketClient.disconnect()
    }
  }, [startConnection])

  return {
    setStartConnection,
  }
}

export default UseWebSocket
