import { getSegmentVideo } from '../triggers.actions'

export default (builder) => {
  builder
    .addCase(getSegmentVideo.pending, (state) => {
      state.video.loading = true
    })
    .addCase(getSegmentVideo.fulfilled, (state, action) => {
      state.video.loading = false
      state.video.error = null
      state.video.url = action.payload
      state.video.playing = true
    })
    .addCase(getSegmentVideo.rejected, (state, action) => {
      state.video.loading = false
      state.video.error = action.payload
    })
}
