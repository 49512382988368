import React, { useEffect, useState } from 'react'
import { Box, Stack, Breadcrumbs, Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getCustomer, getCustomers } from '../../store/slices/customers/customers.actions'
import { COLOR } from '../../utils/constants/color.constants'
import UIPaper from '../../ui/UIPaper/UIPaper'
import UITextHeadlineLarge from '../../ui/UIText/UITextHeadlineLarge'
import AdminsTable from './AdminsTable'
import UITextBodyLarge from '../../ui/UIText/UITextBodyLarge'
import UIButtonAccentSmall from '../../ui/UIButton/UIButtonAccentSmall'
import AddAdminModal from '../../components/Modals/AddAdminModal/AddAdminModal'
import UIButtonLinkPlain from '../../ui/UIButton/UIButtonLinkPlain'

const Customer = () => {
  const isLoading = useSelector((state) => state.customers.customer.isLoading)
  const customers = useSelector((state) => state.customers.customers)
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const [addAdminModal, setAddAdminModal] = useState(false)

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(getCustomers())
    }
  }, [customers])

  useEffect(() => {
    dispatch(getCustomer({ id: params.id }))
  }, [])

  return (
    <Box
      sx={{
        borderLeft: '1px solid',
        borderColor: COLOR.GRAY_EXTRA_LIGHT,
        height: '100vh',
        overflow: 'auto',
        p: '30px',
        pt: '60px',
      }}
    >
      <UIPaper>
        <Stack gap="30px">
          <Breadcrumbs maxItems={2} aria-label="breadcrumb">
            <UIButtonLinkPlain onClick={() => navigate('/customers')}>Customers</UIButtonLinkPlain>
            <UITextBodyLarge
              text={customers.find((item) => item.id.toString() === params.id)?.name}
            />
          </Breadcrumbs>
          <UITextHeadlineLarge
            text={customers.find((item) => item.id.toString() === params.id)?.name}
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <UITextBodyLarge text="List of admins" sx={{ fontSize: '18px' }} />
            <UIButtonAccentSmall
              sx={{ display: 'flex', gap: '5px' }}
              onClick={() => setAddAdminModal(true)}
            >
              <Add sx={{ fill: COLOR.WHITE }} /> Add new admin
            </UIButtonAccentSmall>
          </Stack>
          <AdminsTable />
          <AddAdminModal open={addAdminModal} setOpen={setAddAdminModal} />
        </Stack>
      </UIPaper>
    </Box>
  )
}

export default Customer
