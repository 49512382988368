import React, { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, CardMedia, Card } from '@mui/material'
import { useSelector } from 'react-redux'
import { COLOR } from '../../utils/constants/color.constants'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'

const SegmentGroupPlayer = () => {
  const { loading, url, playing } = useSelector((state) => state.triggers.video)

  const videoRef = useRef()

  const handlePlayPause = () => {
    if (videoRef.current?.paused && playing) {
      try {
        videoRef.current?.play()
      } catch (e) {
        console.log(e)
      }
    } else if (!playing && !videoRef.current?.paused) {
      try {
        videoRef.current?.pause()
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (!loading && url && playing && videoRef.current) {
      handlePlayPause()
    }
  }, [loading, url, playing])

  return (
    <Box
      sx={{
        width: '600px',
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLOR.GRAY_LIGHT,
        position: 'relative',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <VideoPlayer url={url} videoRef={videoRef} height="300px" />
      )}
    </Box>
  )
}

export default SegmentGroupPlayer
