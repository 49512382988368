import {
  currentStatusTypes,
  globalStatusTypes,
  recordingModeTypes,
  recordingSourceTypes,
  resolutionTypes,
  uploadModeTypes,
} from '../constants/enums.constants'

export const getCurrentStatus = (systemName) => {
  const statusType = currentStatusTypes.find((item) => item.systemName === systemName)
  if (statusType) {
    return statusType?.name
  }
  return 'Unknown'
}

export const getGlobalStatus = (systemName) => {
  const statusType = globalStatusTypes.find((item) => item.systemName === systemName)
  if (statusType) {
    return statusType?.name
  }
  return 'Unknown'
}

export const getRecordingSource = (systemName) => {
  const statusType = recordingSourceTypes.find((item) => item.systemName === systemName)
  if (statusType) {
    return statusType?.name
  }
  return 'Unknown'
}

export const getUploadModeType = (systemName) => {
  const statusType = uploadModeTypes.find((item) => item.systemName === systemName)
  if (statusType) {
    return statusType?.name
  }
  return 'Unknown'
}

export const getResolutionType = (systemName) => {
  const statusType = resolutionTypes.find((item) => item.systemName === systemName)
  if (statusType) {
    return statusType?.name
  }
  return 'Unknown'
}

export const getRecordingModeType = (systemName) => {
  const statusType = recordingModeTypes.find((item) => item.systemName === systemName)
  if (statusType) {
    return statusType?.name
  }
  return 'Unknown'
}

export const getTypesList = (list, allSelector) => {
  const options = list.map((item) => ({ label: item.name, value: item.systemName }))
  if (allSelector) {
    return [{ label: 'All', value: 'all' }, ...options]
  }
  return options
}
