import * as Yup from 'yup'
import { formErrors } from '../../errors.constants'

export const editDeviceValidationSchema = Yup.object().shape({
  name: Yup.string().required(formErrors.required),
  accelerationValue: Yup.number()
    .typeError('Sensitivity must be a number')
    .min(0, 'From 0 to 100')
    .max(100, 'From 0 to 100'),
})
