import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Box, Stack } from '@mui/material'
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material'
import UITextBodyMedium from '../UIText/UITextBodyMedium'
import { COLOR } from '../../utils/constants/color.constants'
import { tableCellTypes } from '../../utils/constants/enums.constants'
import { generateReactKey } from '../../utils/helpers/reactKey.helper'
import UIIconButton from '../UIButton/UIIconButton/UIIconButton'
import { UISize } from '../../utils/constants/ui.constants'

const UITable = ({
  head,
  data,
  emptyText,
  renderActionItems,
  variant = 'primary',
  sort,
  setSort,
}) => {
  return (
    <>
      <Table className={`table-variant-${variant}`}>
        <TableHead>
          <TableRow
            sx={{
              background: COLOR.GRAY_EXTRA_LIGHT,
              py: '16px',
            }}
          >
            {head.map((item) => {
              return (
                <TableCell
                  key={generateReactKey()}
                  sx={{ py: item?.sortable ? '5px' : '14px', ...item?.sx }}
                >
                  <Stack direction="row" gap="10px" alignItems="center">
                    {item.name}
                    {item?.sortable ? (
                      <Stack direction="row" gap="0">
                        <UIIconButton
                          size={UISize.sm}
                          className={sort === item?.sortDesc ? 'active' : ''}
                          onClick={() => setSort(item?.sortDesc)}
                        >
                          <ArrowDownwardRounded />
                        </UIIconButton>
                        <UIIconButton
                          size={UISize.sm}
                          className={sort === item?.sortAsc ? 'active' : ''}
                          onClick={() => setSort(item?.sortAsc)}
                        >
                          <ArrowUpwardRounded />
                        </UIIconButton>
                      </Stack>
                    ) : null}
                  </Stack>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={generateReactKey()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      className={cell.type === tableCellTypes.ACTION ? 'action-cell' : ''}
                      key={generateReactKey()}
                      sx={{
                        '&.action-cell': {
                          px: 0,
                          py: 0,
                          height: '100%',
                        },
                        ...cell?.sx,
                      }}
                    >
                      {cell.type === tableCellTypes.TEXT ? (
                        <UITextBodyMedium text={cell.value} />
                      ) : null}
                      {cell.type === tableCellTypes.COMPONENT ? cell.value : null}
                      {cell.type === tableCellTypes.ACTION && renderActionItems
                        ? renderActionItems(row)
                        : null}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {data.length === 0 ? (
        <Box
          sx={{
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UITextBodyMedium
            text={emptyText || 'No data available'}
            sx={{ color: COLOR.GRAY_LIGHT }}
          />
        </Box>
      ) : null}
    </>
  )
}

export default UITable

// <IconButton
//   sx={{
//     width: '100%',
//     height: '49.5px',
//     borderRadius: 0
//   }}
// >
//   {cell.value === 'upload' ? (
//     <CloudUpload
//       sx={{
//         fill: COLOR.GRAY_DARK
//       }}
//     />
//   ) : null}
//   {cell.value === 'play' ? (
//     <PlayCircle
//       sx={{
//         fill: COLOR.GRAY_DARK
//       }}
//     />
//   ) : null}
// </IconButton>
