import $api from '../api'

class SegmentsApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      segments: '/segments',
      videoLink: '/gcs/video-link',
      commands: {
        upload: (id) => `/device/${id}/command/upload-segment`,
      },
      segmentGroupById: (id) => `/segment-group/${id}`,
    }
  }

  getSegments = async (deviceId, params) => {
    const response = await this.#api.get(this.#endPoints.segments, {
      params: { deviceId, ...params },
    })
    return response
  }

  uploadSegments = async (deviceId, data) => {
    const response = await this.#api.post(this.#endPoints.commands.upload(deviceId), data)
    return response
  }

  getVideoLink = async (segmentId) => {
    const response = await this.#api.get(this.#endPoints.videoLink, {
      params: { segmentId },
    })
    return response
  }

  getSegmentGroup = async (segmentGroupId) => {
    const response = await this.#api.get(this.#endPoints.segmentGroupById(segmentGroupId))
    return response
  }
}

const segmentsApiService = new SegmentsApiService({ api: $api })

export default segmentsApiService
