import React from 'react'
import { Form, Formik } from 'formik'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addAdmin, getCustomer } from '../../../../store/slices/customers/customers.actions'
import FormInput from '../../FormComponents/FormInput/FormInput'
import UITextBodyMedium from '../../../../ui/UIText/UITextBodyMedium'
import { COLOR } from '../../../../utils/constants/color.constants'
import UIButtonMedium from '../../../../ui/UIButton/UIButtonMedium'
import UIButtonMediumDanger from '../../../../ui/UIButton/UIButtomMediumDanger'
import { addAdminFormValidationSchema, initialAddAdminFormState } from './addAdminForm.constants'

const AddAdminForm = ({ onClose }) => {
  const params = useParams()
  const isLoading = useSelector((state) => state.customers.addAdmin.isLoading)
  const error = useSelector((state) => state.customers.addAdmin.error)
  const dispatch = useDispatch()

  const handleSubmit = (values, { resetForm }) => {
    dispatch(addAdmin({ id: params.id, data: values }))
      .unwrap()
      .then(() => {
        onClose()
        dispatch(getCustomer({ id: params.id }))
        resetForm()
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  return (
    <Formik
      initialValues={initialAddAdminFormState}
      onSubmit={handleSubmit}
      validationSchema={addAdminFormValidationSchema}
    >
      {(props) => (
        <Form>
          <Stack gap="30px">
            <FormInput
              type="text"
              id="firstName"
              label="First name"
              name="name"
              disabled={isLoading}
              required
            />
            <FormInput
              type="text"
              id="lastName"
              label="Last name"
              name="surname"
              disabled={isLoading}
              required
            />
            <FormInput
              type="text"
              id="email"
              label="Email"
              name="mail"
              disabled={isLoading}
              required
            />
            <FormInput
              type="text"
              id="username"
              label="Username"
              name="username"
              disabled={isLoading}
              required
            />
            <FormInput
              type="password"
              id="password"
              label="Password"
              name="password"
              disabled={isLoading}
              required
            />
            {error && error !== '' ? (
              <UITextBodyMedium text={error} sx={{ color: COLOR.RED_ERROR }} />
            ) : null}
            <Stack direction="row" gap="10px" sx={{ mt: '10px' }}>
              <UIButtonMediumDanger onClick={onClose} fullWidth>
                Cancel
              </UIButtonMediumDanger>
              <UIButtonMedium
                fullWidth
                type="submit"
                disabled={isLoading || (props.touched && !props.isValid)}
              >
                Add
              </UIButtonMedium>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default AddAdminForm
