import React from 'react'
import { Tab } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'

const UITab = ({ label, sx, ...props }) => {
  return (
    <Tab
      sx={{
        flex: 1,
        maxWidth: '50%',
        background: COLOR.WHITE,
        borderBottom: '1px solid',
        borderColor: COLOR.GRAY_LIGHT,
        '&.Mui-selected': {
          color: COLOR.ORANGE,
        },
        ...sx,
      }}
      label={label}
      {...props}
    />
  )
}

export default UITab
