import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { ContentCopyRounded, DeleteRounded, DoneRounded, EditRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { deleteApiKey, getApiKeys, updateApiKey } from '../../store/slices/apiKeys/apiKeys.actions'
import UIIconButton from '../../ui/UIButton/UIIconButton/UIIconButton'
import { copyToClipboard } from '../../utils/helpers/common.helpers'
import { COLOR } from '../../utils/constants/color.constants'
import { VARIANT } from '../../utils/constants/ui.constants'
import EditApiKeyModal from '../../components/Modals/EditApiKeyModal/EditApiKeyModal'
import DeleteApiKeyModal from '../../components/Modals/ConfirmationModals/DeleteApiKeyModal'

const ApiKeysActionItems = ({ data }) => {
  const isDeleting = useSelector((state) => state.apiKeys.delete.isLoading)

  const apiKey = data?.initialData
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [copiedToClipboardId, setCopiedToClipboardId] = useState('')
  const [copyTimeout, setCopyTimeout] = useState(null)

  const handleDelete = async () => {
    const res = await dispatch(deleteApiKey({ id: apiKey.id }))
    if (deleteApiKey.fulfilled.match(res)) {
      setDeleteModal(false)
      toast.success('API key has been deleted')
      dispatch(getApiKeys())
    }
  }

  const handleCopyToClipboard = async (text, id) => {
    const res = await copyToClipboard(text)
    if (res) {
      setCopiedToClipboardId(id)
      if (copyTimeout) {
        clearTimeout(copyTimeout)
      }
      const timeout = setTimeout(() => {
        setCopiedToClipboardId('')
      }, 3000)
      setCopyTimeout(timeout)
    }
  }

  return (
    <Stack direction="row" justifyContent="flex-end">
      <UIIconButton
        text="Copy API key to clipboard"
        onClick={() => handleCopyToClipboard(apiKey.apiKey, apiKey.id)}
      >
        <ContentCopyRounded />
        {copiedToClipboardId === apiKey.id ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: '3px',
              right: '3px',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              background: COLOR.WHITE,
              border: '1px solid',
              borderColor: COLOR.GREEN,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DoneRounded sx={{ fill: COLOR.GREEN, fontSize: '12px !important' }} />
          </Box>
        ) : null}
      </UIIconButton>
      <UIIconButton
        text="Edit API key"
        onClick={() => {
          setEditModal(true)
        }}
      >
        <EditRounded />
      </UIIconButton>
      <UIIconButton
        text="Delete API key"
        variant={VARIANT.DANGER}
        onClick={() => setDeleteModal(true)}
      >
        <DeleteRounded />
      </UIIconButton>
      {editModal && (
        <EditApiKeyModal open={editModal} setOpen={setEditModal} data={data?.initialData} />
      )}
      {deleteModal && (
        <DeleteApiKeyModal
          isLoading={isDeleting}
          data={data?.initialData}
          open={deleteModal}
          setOpen={setDeleteModal}
          handleSubmit={handleDelete}
        />
      )}
    </Stack>
  )
}

export default ApiKeysActionItems
