import $api from '../api'
import { DEMO_TOKEN, demoCredentials } from '../../utils/constants/demo.constants'

class AuthApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      auth: '/auth/login',
      refresh: '/auth/refresh-token',
      userInfo: '/user/me',
    }
  }

  authToken = async ({ username, password }) => {
    const response = await this.#api.post(this.#endPoints.auth, { username, password })
    return response
  }

  getUserInfo = async () => {
    const response = await this.#api.get(this.#endPoints.userInfo)
    return response
  }
}

const authApiService = new AuthApiService({ api: $api })

export default authApiService
