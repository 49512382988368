import React, { useState } from 'react'
import { IconButton, Stack } from '@mui/material'
import { Settings, Block, UnarchiveRounded, DeleteOutlineRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  banDevice,
  deleteDevice,
  getDevices,
  recoverDevice,
} from '../../../store/slices/devices/devices.actions'
import { setPage, setSelectedDevice } from '../../../store/slices/devices/devices.reducer'
import UIIconButtonMedium from '../../../ui/UIButton/UIIconButton/UIIconButtonMedium'
import EditDeviceModal from '../../../components/Modals/EditDeviceModal/EditDeviceModal'
import DeleteDeviceModal from '../../../components/Modals/ConfirmationModals/DeleteDeviceModal'
import BanDeviceModal from '../../../components/Modals/ConfirmationModals/BanDeviceModal'
import { COLOR } from '../../../utils/constants/color.constants'
import { globalStatusEnums } from '../../../utils/constants/enums.constants'
import UITooltip from '../../../ui/UITooltip/UITooltip'

const RecorderSettings = () => {
  const [editDeviceModal, setEditDeviceModal] = useState(false)
  const device = useSelector((state) => state.devices.selectedDevice)
  const currentPage = useSelector((state) => state.devices.pagination.page)
  const globalStatus = useSelector((state) => state.devices.selectedDevice?.deviceGlobalStatus)
  const isLoading = useSelector((state) => state.devices.manageDeviceStatus.isLoading)
  const isDeletionLoading = useSelector((state) => state.devices.deleteDevice.isLoading)
  const dispatch = useDispatch()

  const [deleteDeviceModal, setDeleteDeviceModal] = useState(false)
  const [banDeviceModal, setBanDeviceModal] = useState(false)

  const handleDeleteDevice = async () => {
    const response = await dispatch(deleteDevice({ id: device?.id }))
    if (deleteDevice.fulfilled.match(response)) {
      if (currentPage === 0) {
        dispatch(getDevices())
      } else {
        dispatch(setPage(0))
      }
      dispatch(setSelectedDevice(null))
      setDeleteDeviceModal(false)
    }
  }

  const handleBanDevice = async () => {
    const res = await dispatch(banDevice({ id: device.id }))
    if (banDevice.fulfilled.match(res)) {
      toast.success(`Recorder "${device?.name}" has been banned`)
      if (currentPage === 0) {
        dispatch(getDevices())
      } else {
        dispatch(setPage(0))
      }
      setBanDeviceModal(false)
      dispatch(setSelectedDevice(null))
    }
  }

  const handleRecoverDevice = async () => {
    const res = await dispatch(recoverDevice({ id: device.id }))
    if (recoverDevice.fulfilled.match(res)) {
      toast.success(`Recorder "${device?.name}" has been recovered`)
      dispatch(getDevices())
    }
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{
          position: 'absolute',
          top: '23px',
          right: '60px',
        }}
        alignItems="center"
      >
        {globalStatus !== globalStatusEnums.BANNED ? (
          <UITooltip text="Ban recorder">
            <UIIconButtonMedium
              accentColor={COLOR.RED_DARK}
              disabled={isLoading}
              onClick={() => setBanDeviceModal(true)}
            >
              <Block />
            </UIIconButtonMedium>
          </UITooltip>
        ) : (
          <UITooltip text="Revocer recorder">
            <UIIconButtonMedium
              accentColor={COLOR.GREEN}
              disabled={isLoading}
              onClick={() => handleRecoverDevice()}
            >
              <UnarchiveRounded />
            </UIIconButtonMedium>
          </UITooltip>
        )}
        <UITooltip text="Delete recorder">
          <UIIconButtonMedium
            accentColor={COLOR.RED_DARK}
            onClick={() => setDeleteDeviceModal(true)}
          >
            <DeleteOutlineRounded />
          </UIIconButtonMedium>
        </UITooltip>
        <UITooltip text="Settings">
          <IconButton onClick={() => setEditDeviceModal(true)}>
            <Settings
              sx={{
                fill: COLOR.GRAY_DARK,
              }}
            />
          </IconButton>
        </UITooltip>
      </Stack>
      <EditDeviceModal open={editDeviceModal} setOpen={setEditDeviceModal} />
      {banDeviceModal && (
        <BanDeviceModal
          open={banDeviceModal}
          setOpen={setBanDeviceModal}
          data={device}
          isLoading={isLoading}
          handleSubmit={handleBanDevice}
        />
      )}
      <DeleteDeviceModal
        open={deleteDeviceModal}
        setOpen={setDeleteDeviceModal}
        data={{ name: device?.name ?? `Camera n${device?.id}` }}
        handleSubmit={handleDeleteDevice}
        isLoading={isDeletionLoading}
      />
    </>
  )
}

export default RecorderSettings
