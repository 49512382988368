import * as Yup from 'yup'
import { formErrors } from '../../errors.constants'

export const initialAddAdminFormState = {
  name: '',
  surname: '',
  mail: '',
  username: '',
  password: '',
}

export const addAdminFormValidationSchema = Yup.object().shape({
  name: Yup.string().required(formErrors.required),
  surname: Yup.string().required(formErrors.required),
  mail: Yup.string().required(formErrors.required).email(formErrors.email),
  username: Yup.string().required(formErrors.required),
  password: Yup.string()
    .required(formErrors.required)
    .min(6, formErrors.min6)
    .max(20, formErrors.max20),
})
