export const didUploadTimeChange = (initialData, updatedData) => {
  return (
    parseFloat(initialData?.minutesBeforeTrigger) !==
      parseFloat(updatedData?.minutesBeforeTrigger) ||
    parseFloat(initialData?.minutesAfterTrigger) !== parseFloat(updatedData?.minutesAfterTrigger)
  )
}

export const didConfigurationChange = (initialData, updatedData) => {
  return (
    initialData?.recordingMode !== updatedData?.recordingMode ||
    initialData?.recordingResolution !== updatedData?.recordingResolution ||
    initialData?.recordingSource !== updatedData?.recordingSource ||
    initialData?.uploadMode !== updatedData?.uploadMode
  )
}

export const didAccelerationChange = (initialData, updatedData) => {
  return (
    parseFloat(initialData?.accelerationValue) !== parseFloat(updatedData?.accelerationValue) ||
    initialData?.isEnabled !== updatedData?.isEnabled
  )
}
