import React from 'react'
import { Typography } from '@mui/material'

const UiTextTitleSmall = ({ text, sx, ...props }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.1px',
        ...sx,
      }}
      {...props}
    >
      {text}
    </Typography>
  )
}

export default UiTextTitleSmall
