import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { adaptParams } from '../../../api/api.helpers'
import apiKeyApiService from '../../../api/apiKeyApiService/apiKeyApiService'

export const updateApiKey = createAsyncThunk(
  'apiKeys/updateApiKey',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await apiKeyApiService.updateApiKey(id, data)
      return response.data
    } catch (error) {
      toast.error(error?.message ?? error)
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteApiKey = createAsyncThunk(
  'apiKeys/deleteApiKey',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await apiKeyApiService.deleteApiKey(id)
      return response.data
    } catch (error) {
      if (!error.response) throw error
      return rejectWithValue(error.response.data)
    }
  },
)

export const createApiKey = createAsyncThunk(
  'apiKeys/createApiKey',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await apiKeyApiService.createApiKey(data)
      return response.data
    } catch (error) {
      if (!error.response) throw error
      return rejectWithValue(error.response.data)
    }
  },
)

export const getApiKeys = createAsyncThunk(
  'apiKeys/getApiKeys',
  async (_, { rejectWithValue, getState }) => {
    try {
      const params = getState().apiKeys.apiKeys.queryParams
      const response = await apiKeyApiService.getApiKeys(adaptParams(params))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
