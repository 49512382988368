import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedDevice } from '../../../store/slices/devices/devices.reducer'
import { COLOR } from '../../../utils/constants/color.constants'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import UITextTitleMedium from '../../../ui/UIText/UITextTitleMedium'
import UITextLabelLarge from '../../../ui/UIText/UITextLabelLarge'
import {
  getCurrentStatus,
  getGlobalStatus,
  getRecordingModeType,
  getRecordingSource,
  getUploadModeType,
} from '../../../utils/helpers/enums.helper'
import useDeviceSocket from '../../../hooks/useDeviceSocket'

const RecorderListItem = ({ data }) => {
  const selectedDevice = useSelector((state) => state.devices.selectedDevice)
  const socketClient = useSelector((state) => state.auth.socketClient)
  const dispatch = useDispatch()
  const { setSubscribeToEvents, eventMessage } = useDeviceSocket({ device: data })

  useEffect(() => {
    if (data?.id && socketClient?.connected) {
      setSubscribeToEvents(true)
    }
    return () => {
      setSubscribeToEvents(false)
    }
  }, [data?.id])

  return (
    <Box
      className={[
        data?.id === selectedDevice?.id ? 'active' : '',
        data?.event ? 'attention' : '',
      ].join(' ')}
      sx={{
        p: '6px',
        borderBottom: '1px solid',
        borderColor: COLOR.GRAY_LIGHT,
        display: 'flex',
        cursor: 'pointer',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          boxShadow: `0 0 0 1.5px ${COLOR.GRAY_MEDIUM} inset`,
          transition: '0.25s all ease-in-out',
        },
        '&.attention': {
          boxShadow: `0 0 0 1.5px ${COLOR.ORANGE} inset`,
          transition: '0.25s all ease-in-out',
        },
        '&.active': {
          boxShadow: `0 0 0 1.5px ${COLOR.GRAY_MEDIUM} inset`,
          transition: '0.25s all ease-in-out',
        },
      }}
      onClick={() => {
        dispatch(setSelectedDevice(data))
      }}
    >
      <Box
        sx={{
          width: '220px',
          height: '123px',
          background: COLOR.GRAY_LIGHT,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          verticalAlign: 'top',
          '& img': {
            objectFit: 'fill',
            width: '100%',
            verticalAlign: 'top',
          },
        }}
      >
        {data?.base64Preview ? (
          <img src={`data:image/jpeg;base64,${data?.base64Preview}`} alt="" />
        ) : (
          <UITextTitleSmall text="PREVIEW" sx={{ color: COLOR.GRAY_MEDIUM_LIGHT }} />
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          px: '10px',
          py: '5px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '6px',
          }}
        >
          <UITextTitleMedium text={data?.name ?? `Camera n${data?.id}`} />
          <UITextLabelLarge
            text={getCurrentStatus(data.deviceCurrentStatus)}
            sx={{
              color: data.deviceCurrentStatus === 'ONLINE' ? COLOR.GREEN_SUCCESS : COLOR.RED_ERROR,
            }}
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <UITextLabelLarge text="Status:" />
            <UITextLabelLarge text={getGlobalStatus(data.deviceGlobalStatus)} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <UITextLabelLarge text="Record source:" />
            <UITextLabelLarge
              text={getRecordingSource(data?.deviceConfiguration?.recordingSource)}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <UITextLabelLarge text="Upload mode:" />
            <UITextLabelLarge text={getUploadModeType(data?.deviceConfiguration?.uploadMode)} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <UITextLabelLarge text="Recording mode" />
            <UITextLabelLarge
              text={getRecordingModeType(data?.deviceConfiguration?.recordingMode)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RecorderListItem
