import { createSlice } from '@reduxjs/toolkit'
import getSavedSegmentsBuilder from './builders/getSavedSegments.builder'
import uploadSegmentsBuilder from './builders/uploadSegments.builder'
import getVideoLinkBuilder from './builders/getVideoLink.builder'
import {
  compareSegmentNames,
  removeTimeZoneFromSegmentName,
} from '../../../utils/helpers/date.helper'
import {
  getPreferenceKey,
  preferenceKeys,
  setPreferenceKey,
} from '../../../utils/helpers/localStorage.helpers'

const initialState = {
  deviceId: null,
  savedSegments: [],
  savedSegmentsPagination: {
    page: 0,
    size: getPreferenceKey(preferenceKeys.SAVED_SEGMENTS_PER_PAGE) ?? 10,
    total: 0,
  },
  isLoading: false,
  error: '',
  currentSegments: [],
  sendingSegments: false,
  currentConnectionStatus: '',
  uploadSegment: {
    isLoading: false,
    error: null,
  },
  video: {
    segmentId: null,
    loading: false,
    error: null,
    url: null,
    playing: false,
  },
}

const segmentsSlice = createSlice({
  name: 'segmentsReducer',
  initialState,
  reducers: {
    setCurrentConnectionStatus: (state, action) => {
      state.currentConnectionStatus = action.payload
    },
    setSendingSegments: (state, action) => {
      state.sendingSegments = action.payload
      if (action.payload) state.currentConnectionStatus = 'Sending segments...'
    },
    setCurrentSegments: (state, action) => {
      state.currentSegments = action.payload
    },
    setCurrentSegmentStatus: (state, action) => {
      const updateSegment = state.currentSegments.find((item) =>
        compareSegmentNames(item.segmentName, action.payload.segmentName),
      )
      if (updateSegment) {
        updateSegment.status = action.payload.status
        if (updateSegment.isSendingCommand) updateSegment.isSendingCommand = false
      }
    },
    setVideoSegmentId: (state, action) => {
      state.video.segmentId = action.payload
    },
    setSavedSegmentsPage: (state, action) => {
      state.savedSegmentsPagination.page = action.payload
    },
    setSavedSegmentsSize: (state, action) => {
      state.savedSegmentsPagination.size = action.payload
      state.savedSegmentsPagination.page = 0
      setPreferenceKey(preferenceKeys.SAVED_SEGMENTS_PER_PAGE, action.payload)
    },
    setIsSendingUploadingCommand: (state, action) => {
      const updateSegment = state.currentSegments.find(
        (item) => item.segmentName === action.payload.segmentName,
      )
      if (updateSegment) {
        updateSegment.isSendingCommand = true
      }
    },
    resetSegmentsData: (state) => {
      state.video = initialState.video
      state.currentSegments = []
      state.savedSegments = []
      state.uploadSegment = initialState.uploadSegment
      state.currentConnectionStatus = initialState.currentConnectionStatus
      state.sendingSegments = initialState.sendingSegments
    },
  },
  extraReducers: (builder) => {
    getSavedSegmentsBuilder(builder)
    uploadSegmentsBuilder(builder)
    getVideoLinkBuilder(builder)
  },
})

export const {
  setSendingSegments,
  setCurrentConnectionStatus,
  setCurrentSegments,
  addUploadingSegment,
  resetSegmentsData,
  setUploadedSegment,
  setVideoSegmentId,
  setSavedSegmentsPage,
  setSavedSegmentsSize,
  setCurrentSegmentStatus,
  setIsSendingUploadingCommand,
} = segmentsSlice.actions

export default segmentsSlice.reducer
