import { createSlice, current } from '@reduxjs/toolkit'
import getDevicesBuilder from './builders/getDevices.builder'
import getDeviceRegisterTokenBuilder from './builders/getDeviceRegisterToken.builder'
import updateDeviceConfigurationBuilder from './builders/updateDeviceConfiguration.builder'
import banDeviceBuilder from './builders/banDevice.builder'
import recoverDeviceBuilder from './builders/recoverDevice.builder'
import deleteDeviceBuilder from './builders/deleteDevice.builder'
import editDeviceBuilder from './builders/editDevice.builder'
import { globalStatusEnums } from '../../../utils/constants/enums.constants'
import {
  getPreferenceKey,
  preferenceKeys,
  setPreferenceKey,
} from '../../../utils/helpers/localStorage.helpers'

const initialState = {
  devices: [],
  events: [],
  filters: {
    currentStatus: 'all',
    globalStatus: globalStatusEnums.ACTIVE,
    collapsed: true,
  },
  pagination: {
    page: 0,
    size: getPreferenceKey(preferenceKeys.CAM_PER_PAGE) ?? 5,
    total: 0,
  },
  searchLine: '',
  isLoading: false,
  error: null,
  selectedDevice: null,
  addDevice: {
    isLoadingToken: false,
    token: null,
    error: null,
  },
  editDevice: {
    isLoading: false,
    error: null,
  },
  patchDeviceConfiguration: {
    isLoading: false,
    error: null,
  },
  manageDeviceStatus: {
    isLoading: false,
    error: null,
  },
  deleteDevice: {
    isLoading: false,
    error: null,
  },
}

const devicesSlice = createSlice({
  name: 'devicesReducer',
  initialState,
  reducers: {
    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload
    },
    resetAddDeviceData: (state) => {
      state.addDevice = initialState.addDevice
    },
    setFilterGlobalStatus: (state, action) => {
      state.filters.globalStatus = action.payload
      state.pagination.page = 0
    },
    setFilterCurrentStatus: (state, action) => {
      state.filters.currentStatus = action.payload
      state.pagination.page = 0
    },
    setFiltersCollapsed: (state, action) => {
      state.filters.collapsed = action.payload
    },
    setPage: (state, action) => {
      state.pagination.page = action.payload
    },
    setSize: (state, action) => {
      state.pagination.size = action.payload
      state.pagination.page = 0
      setPreferenceKey(preferenceKeys.CAM_PER_PAGE, action.payload)
    },
    setDeviceCurrentStatus: (state, action) => {
      const { id, status } = action.payload
      const targetDevice = state.devices.find((item) => item.id === id)
      if (targetDevice && targetDevice?.deviceCurrentStatus !== status) {
        targetDevice.deviceCurrentStatus = status
      }
      if (state.selectedDevice?.id === id && state.selectedDevice?.deviceCurrentStatus !== status) {
        state.selectedDevice.deviceCurrentStatus = status
      }
    },
    setDeviceConfiguration: (state, action) => {
      const { id, body } = action.payload
      const targetDevice = state.devices.find((item) => item.id === id)
      if (targetDevice) {
        targetDevice.deviceConfiguration = body
      }
      if (state.selectedDevice?.id === id) {
        state.selectedDevice.deviceConfiguration = body
      }
    },
    setDevicePreview: (state, action) => {
      const { id, body } = action.payload
      const targetDevice = state.devices.find((item) => item.id === id)
      if (targetDevice) {
        targetDevice.base64Preview = body
      }
      if (state.selectedDevice?.id === id) {
        state.selectedDevice.base64Preview = body
      }
    },
    setSearchLine: (state, action) => {
      state.searchLine = action.payload
      state.pagination.page = 0
    },
    setDeviceEvent: (state, action) => {
      state.events.push(action.payload)
      const updateDevice = state.devices.find((item) => item?.id === action.payload?.deviceId)
      if (updateDevice) {
        updateDevice.event = action.payload.event
      }
      if (state.selectedDevice && state.selectedDevice?.id === action.payload?.deviceId) {
        state.selectedDevice.event = action.payload.event
      }
    },
    resetDeviceEvent: (state, action) => {
      state.events = state.events.filter((item) => item?.deviceId !== action.payload.deviceId)
      const updateDevice = state.devices.find((item) => item?.id === action.payload?.deviceId)
      if (updateDevice) {
        updateDevice.event = null
      }
      if (state.selectedDevice && state.selectedDevice?.id === action.payload?.deviceId) {
        state.selectedDevice.event = null
      }
    },
  },
  extraReducers: (builder) => {
    getDevicesBuilder(builder)
    getDeviceRegisterTokenBuilder(builder)
    updateDeviceConfigurationBuilder(builder)
    banDeviceBuilder(builder)
    recoverDeviceBuilder(builder)
    deleteDeviceBuilder(builder)
    editDeviceBuilder(builder)
  },
})

export const {
  setSelectedDevice,
  resetAddDeviceData,
  setFilterGlobalStatus,
  setFilterCurrentStatus,
  setFiltersCollapsed,
  setPage,
  setSize,
  setDeviceCurrentStatus,
  setDeviceConfiguration,
  setDevicePreview,
  setSearchLine,
  setDeviceEvent,
  resetDeviceEvent,
} = devicesSlice.actions

export default devicesSlice.reducer
