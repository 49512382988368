import { Navigate } from 'react-router-dom'
import Auth from '../pages/Auth/Auth'
import Recorders from '../pages/Recorders/Recorders'
import Settings from '../pages/Settings/Settings'
import Customers from '../pages/Customers/Customers'
import Customer from '../pages/Customer/Customer'
import TriggersHistory from '../pages/TriggersHistory/TriggersHistory'
import SegmentGroup from '../pages/TriggersHistory/SegmentGroup'
import APIKeys from '../pages/APIKeys/APIKeys'

export const publicRoutes = [
  { path: '/', element: <Auth /> },
  { path: '*', element: <Navigate to="/" /> },
]

export const systemAdminRoutes = [
  { path: '/customers', element: <Customers /> },
  { path: '/customers/:id', element: <Customer /> },
  { path: '/settings', element: <Settings /> },
  { path: '/', element: <Navigate to="/customers" /> },
  { path: '*', element: <Navigate to="/customers" /> },
]

export const adminRoutes = [
  { path: '/settings', element: <Settings /> },
  { path: '/recorders', element: <Recorders /> },
  { path: '/history', element: <TriggersHistory /> },
  { path: '/history/segment-groups/:id', element: <SegmentGroup /> },
  { path: '/api-keys', element: <APIKeys /> },
  { path: '/', element: <Navigate to="/recorders" /> },
  { path: '*', element: <Navigate to="/recorders" /> },
]
