import { createApiKey } from '../apiKeys.actions'

export default (builder) => {
  builder
    .addCase(createApiKey.pending, (state) => {
      state.create.isLoading = true
    })
    .addCase(createApiKey.fulfilled, (state) => {
      state.create.isLoading = false
      state.create.error = null
    })
    .addCase(createApiKey.rejected, (state, action) => {
      state.create.isLoading = false
      state.create.error = action.payload
    })
}
