import React from 'react'
import { Button } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'

const UiButtonNav = ({ text, sx, icon, ...props }) => {
  return (
    <Button
      sx={{
        display: 'flex',
        gap: '5px',
        width: '100%',
        textTransform: 'none',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '17.25px',
        fontWeight: 400,
        py: '7.625px',
        px: '17px',
        background: COLOR.WHITE,
        color: COLOR.GRAY_MEDIUM,
        justifyContent: 'flex-start',
        transition: '0.25s all ease-in-out',
        '&:hover': {
          color: COLOR.WHITE,
          background: COLOR.GRAY_MEDIUM,
          transition: '0.25s all ease-in-out',
          '& svg': {
            fill: COLOR.WHITE,
            transition: '0.25s all ease-in-out',
          },
        },
        '&.active': {
          color: COLOR.WHITE,
          background: COLOR.GRAY_MEDIUM,
          fontWeight: 700,
          transition: '0.25s all ease-in-out',
        },
        '& svg': {
          fill: COLOR.GRAY_MEDIUM,
          transition: '0.25s all ease-in-out',
        },
        ...sx,
      }}
      {...props}
    >
      {icon}
      {text}
    </Button>
  )
}

export default UiButtonNav
