import { createSlice } from '@reduxjs/toolkit'
import getCustomersBuilder from './builders/getCustomers.builder'
import addCustomerBuilder from './builders/addCustomer.builder'
import getCustomerBuilder from './builders/getCustomer.builder'
import addAdminBuilder from './builders/addAdmin.builder'
import editAdminBuilder from './builders/editAdmin.builder'
import deleteCustomerBuilder from './builders/deleteCustomer.builder'
import recoverCustomerBuilder from './builders/recoverCustomer.builder'

const initialState = {
  customers: [],
  isLoading: true,
  error: null,
  isLoadingAddCustomer: false,
  errorAddCustomer: null,
  customer: {
    isLoading: false,
    error: null,
    devices: [],
    admins: [],
  },
  addAdmin: {
    isLoading: false,
    error: null,
  },
  editAdmin: {
    isLoading: false,
    error: null,
  },
  deleteCustomer: {
    isLoading: false,
    error: null,
  },
  recoverCustomer: {
    isLoading: false,
    error: null,
  },
}

const customersSlice = createSlice({
  name: 'customersReducer',
  initialState,
  reducers: {
    updateAdmin: (state, action) => {
      const updatedAdmin = state.customer.admins.find(
        (item) => item?.userId === action.payload?.userId,
      )
      if (updatedAdmin) {
        updatedAdmin.name = action.payload.name
        updatedAdmin.surname = action.payload.surname
        updatedAdmin.mail = action.payload.mail
      }
    },
  },
  extraReducers: (builder) => {
    getCustomersBuilder(builder)
    addCustomerBuilder(builder)
    getCustomerBuilder(builder)
    addAdminBuilder(builder)
    editAdminBuilder(builder)
    deleteCustomerBuilder(builder)
    recoverCustomerBuilder(builder)
  },
})

export const { updateAdmin } = customersSlice.actions

export default customersSlice.reducer
