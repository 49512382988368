import React, { useState } from 'react'
import { Box, TextField } from '@mui/material'

const Search = ({ placeholder, ...props }) => {
  return (
    <Box
      sx={{
        height: '56px',
        flex: 1,
      }}
    >
      <TextField
        id="search"
        fullWidth
        name="search1"
        placeholder={placeholder ?? 'Recorder name...'}
        sx={{
          height: '56px',
          borderRadius: 0,
          fontSize: '16px',
          letterSpacing: '0.15px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 0,
            border: 'none',
          },
        }}
        {...props}
      />
    </Box>
  )
}

export default Search
