import { editAdmin } from '../customers.actions'

export default (builder) => {
  builder
    .addCase(editAdmin.pending, (state) => {
      state.editAdmin.isLoading = true
    })
    .addCase(editAdmin.fulfilled, (state, action) => {
      state.editAdmin.isLoading = false
      state.editAdmin.error = null
    })
    .addCase(editAdmin.rejected, (state, action) => {
      state.editAdmin.isLoading = false
      state.editAdmin.error = action.payload
    })
}
