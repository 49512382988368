import { recoverDevice } from '../devices.actions'
import { globalStatusEnums } from '../../../../utils/constants/enums.constants'

export default (builder) => {
  builder
    .addCase(recoverDevice.pending, (state) => {
      state.manageDeviceStatus.isLoading = true
    })
    .addCase(recoverDevice.fulfilled, (state, action) => {
      state.manageDeviceStatus.error = null
      state.manageDeviceStatus.isLoading = false
      if (state.selectedDevice.id === action.payload.id) {
        state.selectedDevice.deviceGlobalStatus = action.payload.response.data?.deviceGlobalStatus
      }
      const updateDevice = state.devices.find((item) => item.id === action.payload.id)
      if (updateDevice) {
        updateDevice.deviceGlobalStatus = action.payload.response.data?.deviceGlobalStatus
      }
    })
    .addCase(recoverDevice.rejected, (state, action) => {
      state.manageDeviceStatus.isLoading = false
      state.manageDeviceStatus.error = action.payload
    })
}
