export const adminSidebarMenu = [
  {
    name: 'Recorders',
    link: '/recorders',
  },
  {
    name: 'History',
    link: '/history',
  },
  {
    name: 'API keys',
    link: '/api-keys',
  },
  {
    name: 'Settings',
    link: '/settings',
  },
]

export const systemAdminMenu = [
  {
    name: 'Customers',
    link: '/customers',
  },
  {
    name: 'Settings',
    link: '/settings',
  },
]
