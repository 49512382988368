import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSendingSegments,
  setCurrentConnectionStatus,
  setCurrentSegments,
} from '../../../store/slices/segments/segments.reducer'
import { resetDeviceEvent } from '../../../store/slices/devices/devices.reducer'
import webSocketApiService from '../../../api/websocketApiService/webSocketApiService'
import useDeviceSocket from '../../../hooks/useDeviceSocket'
import { globalStatusEnums } from '../../../utils/constants/enums.constants'
import RecorderCountdown from '../RecorderSelected/RecorderCountdown'

const SegmentsControls = () => {
  const device = useSelector((state) => state.devices.selectedDevice)
  const sendingSegments = useSelector((state) => state.segments.sendingSegments)
  const currentSegments = useSelector((state) => state.segments.currentSegments)
  const dispatch = useDispatch()
  const { setSubscribeToSegments } = useDeviceSocket({ device })

  const [sendingCommand, setSendingCommand] = useState(false)

  const accelerationInterval = parseFloat(
    device?.deviceConfiguration?.uploadTrigger?.uploadTime?.minutesAfterTrigger,
  )

  const getTimerDurationInSeconds = () => {
    if (device?.event) {
      const startTime = new Date(device?.event?.timestamp).getTime()
      const endTime = startTime + accelerationInterval * 60000
      const currentTime = new Date().getTime()
      if (currentTime >= endTime) return 0
      return (endTime - currentTime) / 1000
    }
    return 0
  }

  const handleSendCommandStart = async () => {
    await webSocketApiService.startSendingSegments(device.id)
    dispatch(setSendingSegments(true))
  }

  const handleStartSendingSegments = () => {
    dispatch(setCurrentConnectionStatus('Connecting to device...'))
    setSubscribeToSegments(true)
    handleSendCommandStart()
    setSendingCommand(true)
  }

  const handleStopAndDisconnect = () => {
    setSubscribeToSegments(false)
    dispatch(setSendingSegments(false))
    dispatch(setCurrentConnectionStatus(''))
    dispatch(setCurrentSegments([]))
  }

  useEffect(() => {
    if (sendingSegments) {
      handleStopAndDisconnect()
    }
    if (
      device.id &&
      device?.deviceCurrentStatus === 'ONLINE' &&
      device?.deviceGlobalStatus === globalStatusEnums.ACTIVE
    ) {
      handleStartSendingSegments()
    } else {
      handleStopAndDisconnect()
    }
  }, [device.id, device?.deviceCurrentStatus])

  useEffect(() => {
    let interval = null
    if (currentSegments.length === 0 && sendingCommand) {
      interval = setInterval(() => {
        handleSendCommandStart()
      }, 1000)
    } else {
      setSendingCommand(false)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [currentSegments, sendingCommand])

  return (
    <Box
      className={device?.event ? 'active' : ''}
      sx={{
        position: 'absolute',
        top: '20px',
        left: '20px',
        alignItems: 'center',
        gap: '8px',
        px: '8px',
        py: '5px',
        borderRadius: '6px',
        boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
        transition: '0.25s all ease-in-out',
        opacity: 0,
        '&.active': {
          opacity: 1,
          transition: '0.25s all ease-in-out',
        },
      }}
    >
      {device?.event ? (
        <RecorderCountdown
          duration={getTimerDurationInSeconds()}
          handleComplete={() => dispatch(resetDeviceEvent({ deviceId: device?.id }))}
        />
      ) : null}
    </Box>
  )
}

export default SegmentsControls
