import React from 'react'
import { Stack } from '@mui/material'
import UIModal from '../../../ui/UIModal/UIModal'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import UIButtonMediumDanger from '../../../ui/UIButton/UIButtomMediumDanger'
import UIButtonMediumHollow from '../../../ui/UIButton/UIButtonMediumHollow'

const DeleteModal = ({ data, open, setOpen, handleSubmit, isLoading }) => {
  return (
    <UIModal wrapperStyles={{ width: '700px' }} open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge>
          Are you sure you want to block customer <br /> &quot;
          <span style={{ fontWeight: 700 }}>{data?.name}</span>&quot;?
        </UITextHeadlineLarge>
        <Stack direction="row" gap="10px">
          <UIButtonMediumHollow fullWidth onClick={() => setOpen(false)}>
            Cancel
          </UIButtonMediumHollow>
          <UIButtonMediumDanger disabled={isLoading} fullWidth onClick={() => handleSubmit()}>
            Block
          </UIButtonMediumDanger>
        </Stack>
      </Stack>
    </UIModal>
  )
}

export default DeleteModal
