import $api from '../api'
import { deviceConfiguration } from '../../utils/constants/enums.constants'
import { getUrlWithParams } from '../../utils/helpers/urls.helper'

class DevicesApiService {
  #api

  #endPoints

  #abortControllers

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      devices: '/devices',
      getDeviceRegisterToken: '/device/register',
      configuration: {
        [deviceConfiguration.uploadMode]: '/device/configuration/upload-mode',
        [deviceConfiguration.recordingSource]: '/device/configuration/recording-source',
        [deviceConfiguration.recordingMode]: '/device/configuration/recording-mode',
        [deviceConfiguration.recordingResolution]: '/device/configuration/recording-resolution',
        wifiOnly: '/device/configuration/wifiOnly',
        triggers: {
          uploadTime: '/device/configuration/triggers/upload-time',
          acceleration: '/device/configuration/triggers/acceleration',
        },
      },
      editConfiguration: '/device/configuration/info',
      editDeviceName: (id) => `/device/${id}/name`,
      ban: (id) => `/device/${id}/ban`,
      recover: (id) => `/device/${id}/unban`,
      delete: (id) => `/device/${id}`,
      deviceById: (id) => `/device/${id}`,
    }
    this.#abortControllers = {}
  }

  getDevices = async (params) => {
    this.#abortControllers.getDevices?.abort()
    this.#abortControllers.getDevices = new AbortController()
    const response = await this.#api.get(this.#endPoints.devices, {
      params,
      signal: this.#abortControllers.getDevices?.signal,
    })
    return response
  }

  getDeviceById = async (deviceId) => {
    const response = await this.#api.get(this.#endPoints.deviceById(deviceId))
    return response
  }

  getDeviceRegisterToken = async (data) => {
    const response = await this.#api.post(
      this.#endPoints.getDeviceRegisterToken,
      {},
      { params: data },
    )
    return response
  }

  patchDeviceConfiguration = async (id, configurationSetting, value) => {
    const response = await this.#api.patch(
      `${
        this.#endPoints.configuration[configurationSetting]
      }?deviceId=${id}&${configurationSetting}=${value}`,
      {},
    )
    return response
  }

  patchWifiOnly = async (deviceId, wifiOnly) => {
    const response = await this.#api.patch(
      this.#endPoints.configuration.wifiOnly,
      {},
      {
        params: {
          deviceId,
          wifiOnly: wifiOnly.toString(),
        },
      },
    )
    return response
  }

  editDeviceConfiguration = async (deviceId, data) => {
    const response = await this.#api.put(this.#endPoints.editConfiguration, data, {
      params: {
        deviceId,
      },
    })
    return response
  }

  editDeviceName = async (deviceId, name) => {
    const response = await this.#api.patch(
      this.#endPoints.editDeviceName(deviceId),
      {},
      {
        params: {
          name,
        },
      },
    )
    return response
  }

  editDeviceAcceleration = async (deviceId, data) => {
    const response = await this.#api.put(
      this.#endPoints.configuration.triggers.acceleration,
      data,
      {
        params: { deviceId },
      },
    )
    return response
  }

  editDeviceUploadTime = async (deviceId, data) => {
    const response = await this.#api.put(this.#endPoints.configuration.triggers.uploadTime, data, {
      params: { deviceId },
    })
    return response
  }

  banDevice = async (id) => {
    const response = await this.#api.post(this.#endPoints.ban(id), {})
    return response
  }

  recoverDevice = async (id) => {
    const response = await this.#api.post(this.#endPoints.recover(id), {})
    return response
  }

  deleteDevice = async (id) => {
    const response = await this.#api.delete(this.#endPoints.delete(id))
    return response
  }
}

const devicesApiService = new DevicesApiService({ api: $api })

export default devicesApiService
