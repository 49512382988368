import React from 'react'
import { Card, CardMedia, Box } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'
import UITextTitleSmall from '../../ui/UIText/UITextTitleSmall'

const VideoPlayer = ({ url, videoRef, preview, height = '359px' }) => {
  return url ? (
    <Card>
      <CardMedia
        component="video"
        controls
        alt="title"
        height={height}
        width="100%"
        src={url}
        muted
        ref={videoRef}
      />
    </Card>
  ) : preview ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          objectFit: 'fill',
          width: '100%',
          verticalAlign: 'top',
        },
      }}
    >
      <img src={`data:image/jpeg;base64,${preview}`} alt="" />
    </Box>
  ) : (
    <UITextTitleSmall text="PREVIEW" sx={{ color: COLOR.GRAY_MEDIUM_LIGHT }} />
  )
}

export default VideoPlayer
