import { recoverCustomer } from '../customers.actions'

export default (builder) => {
  builder
    .addCase(recoverCustomer.pending, (state) => {
      state.recoverCustomer.isLoading = true
    })
    .addCase(recoverCustomer.fulfilled, (state, action) => {
      state.recoverCustomer.isLoading = false
      state.recoverCustomer.error = null
      state.customers.find((item) => item.id === action.payload.id).status = 'ACTIVE'
    })
    .addCase(recoverCustomer.rejected, (state, action) => {
      state.recoverCustomer.isLoading = false
      state.recoverCustomer.error = action.payload
    })
}
