import { toast } from 'react-toastify'
import { getCustomer } from '../customers.actions'
import { ROLES } from '../../../../utils/constants/global.constants'

export default (builder) => {
  builder
    .addCase(getCustomer.pending, (state) => {
      state.customer.isLoading = true
    })
    .addCase(getCustomer.fulfilled, (state, action) => {
      state.customer.isLoading = false
      state.customer.data = action.payload
      state.customer.admins = action.payload.filter((item) => item?.role === ROLES.ADMIN)
      state.customer.devices = action.payload.filter((item) => item?.role === ROLES.DEVICE)
    })
    .addCase(getCustomer.rejected, (state, action) => {
      state.customer.isLoading = false
      state.customer.error = action.payload
      toast.error(action.payload)
    })
}
