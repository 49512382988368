import React from 'react'
import { Box, Stack } from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { logOut } from '../../../store/slices/auth/auth.reducer'
import UIDropdown from '../../../ui/UIDropdown/UIDropdown'
import { demoUsers } from '../../../utils/constants/demo.constants'
import UIButtonNav from '../../../ui/UIButton/UIButtonNav'

const SidebarBottom = () => {
  const dispatch = useDispatch()

  return (
    <Box
      sx={{
        px: '25.88px',
        pb: '34.5px',
      }}
    >
      <Stack gap="10px">
        <UIButtonNav text="Log out" icon={<Logout />} onClick={() => dispatch(logOut())} />
        <UIDropdown list={demoUsers} />
      </Stack>
    </Box>
  )
}

export default SidebarBottom
