import React from 'react'
import { Stack } from '@mui/material'
import { AddRounded, RefreshRounded, FilterListRounded, SearchRounded } from '@mui/icons-material'
import UITextTitleMedium from '../UIText/UITextTitleMedium'
import UIIconButton from '../UIButton/UIIconButton/UIIconButton'

const UITableControls = ({
  title,
  onAdd,
  onRefresh,
  onOpenFilters,
  searchValue,
  setSearchValue,
  viewMode,
  onViewModeChange,
  addText = 'Add',
  refreshText = 'Refresh',
  filterText = 'Filter',
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" gap="16px">
        <UITextTitleMedium fontWeight="700" text={title} sx={{ whiteSpace: 'nowrap' }} />
      </Stack>
      <Stack direction="row" alignItems="center" gap="4px">
        {onAdd && (
          <UIIconButton text={addText} onClick={() => onAdd()}>
            <AddRounded />
          </UIIconButton>
        )}
        {onRefresh && (
          <UIIconButton text={refreshText} onClick={() => onRefresh()}>
            <RefreshRounded />
          </UIIconButton>
        )}
        {onOpenFilters && (
          <UIIconButton text={filterText} onClick={() => onOpenFilters()}>
            <FilterListRounded />
          </UIIconButton>
        )}
      </Stack>
    </Stack>
  )
}

export default UITableControls
