import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import UITable from '../../ui/UITable/UITable'
import { adminsTableHead } from '../../utils/constants/tables.constants'
import { COLOR } from '../../utils/constants/color.constants'
import { getAdminsTableData } from '../../utils/helpers/tables.helper'
import UIIconButtonDelete from '../../ui/UIButton/UIIconButton/UIIconButtonDelete'
import UIIconButtonEdit from '../../ui/UIButton/UIIconButton/UIIconButtonEdit'
import EditAdminModal from '../../components/Modals/EditAdminModal/EditAdminModal'

const AdminsTable = () => {
  const admins = useSelector((state) => state.customers.customer.admins)

  const [editAdminModal, setEditAdminModal] = useState(false)
  const [editAdminModalData, setEditAdminModalData] = useState(null)

  const renderActionItems = (row) => {
    return (
      <Box
        sx={{
          background: 'white',
          height: '100%',
          display: 'flex',
          gap: '5px',
        }}
      >
        <UIIconButtonEdit
          onClick={() => {
            setEditAdminModalData(row?.initialData)
            setEditAdminModal(true)
          }}
        />
        <UIIconButtonDelete disabled />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        borderRadius: '6px',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: COLOR.GRAY_EXTRA_LIGHT,
        boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
      }}
    >
      <UITable
        head={adminsTableHead}
        data={getAdminsTableData(admins)}
        emptyText="No admins have been added yet"
        renderActionItems={renderActionItems}
      />
      <EditAdminModal open={editAdminModal} setOpen={setEditAdminModal} data={editAdminModalData} />
    </Box>
  )
}

export default AdminsTable
