import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { useField } from 'formik'
import { generateReactKey } from '../../../../utils/helpers/reactKey.helper'
import { COLOR } from '../../../../utils/constants/color.constants'

const FormSelect = ({ label, id, list, labelStyles, required, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers

  const handleChange = ({ target }) => {
    setValue(target.value)
  }

  return (
    <FormControl
      fullWidth
      size="small"
      sx={{ position: 'relative' }}
      error={meta.error && meta.touched}
      {...props}
    >
      <InputLabel
        id={`label-${id}`}
        sx={{
          background: COLOR.GRAY_EXTRA_LIGHT,
          px: '4px',
          ...labelStyles,
        }}
      >
        {label} {required ? <span style={{ color: COLOR.RED_ERROR }}>*</span> : ''}
      </InputLabel>
      <Select
        labelId={`label-${id}`}
        id={id}
        sx={{
          boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        onChange={handleChange}
        name={field.name}
        value={field.value}
      >
        {list.map((item) => {
          return (
            <MenuItem key={generateReactKey()} value={item.value}>
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default FormSelect
