import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { setDeviceEvent } from '../store/slices/devices/devices.reducer'
import ImpactToast from '../components/Notifications/ImpactToast'

const generalEventEnums = {
  NEW_TRIGGER_CATCHED: 'NEW_TRIGGER_CATCHED',
}

const generalSocketEndpoints = {
  generalCommands: (id) => `/topic/general-commands/${id}`,
}

const UseGeneralSocket = () => {
  const customerId = useSelector((state) => state.auth.user?.customerId)
  const socketClient = useSelector((state) => state.auth.socketClient)
  const devices = useSelector((state) => state.devices.devices)
  const selectedDevice = useSelector((state) => state.devices.selectedDevice)

  const dispatch = useDispatch()

  const [eventMessage, setEventMessage] = useState(null)

  const handleGeneralCommandEvent = () => {
    const data = JSON.parse(eventMessage?.body)
    const { body } = data
    if (data?.event === generalEventEnums.NEW_TRIGGER_CATCHED) {
      dispatch(
        setDeviceEvent({
          deviceId: body?.deviceId,
          event: {
            type: 'ACCELERATION',
            timestamp: body?.time,
          },
        }),
      )
      toast.warning(
        ({ closeToast, toastProps }) => (
          <ImpactToast
            closeToast={closeToast}
            toastProps={toastProps}
            deviceId={body?.deviceId}
            eventData={{
              type: 'ACCELERATION',
              timestamp: body?.time,
            }}
          />
        ),
        {
          autoClose: 10000,
        },
      )
    }
  }

  const handleGeneralCommandError = (message) => {
    toast.error('Error while interacting with general events socket.')
  }

  const handleSubscribeToGeneralEvents = () => {
    if (socketClient) {
      socketClient.subscribe(
        generalSocketEndpoints.generalCommands(customerId),
        (message) => setEventMessage(message),
        (error) => handleGeneralCommandError(error),
      )
    }
  }

  useEffect(() => {
    if (eventMessage) handleGeneralCommandEvent()
  }, [eventMessage])

  return {
    handleSubscribeToGeneralEvents,
  }
}

export default UseGeneralSocket
