import { uploadSegments } from '../segments.actions'

export default (builder) => {
  builder
    .addCase(uploadSegments.pending, (state) => {
      state.uploadSegment.isLoading = true
    })
    .addCase(uploadSegments.fulfilled, (state, action) => {
      state.uploadSegment.isLoading = false
      state.uploadSegment.error = null
    })
    .addCase(uploadSegments.rejected, (state, action) => {
      state.uploadSegment.isLoading = false
      state.uploadSegment.error = action.payload
    })
}
