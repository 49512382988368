import React from 'react'
import { Divider, Grid, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import FormInput from '../../../FormComponents/FormInput/FormInput'
import { onlyPositiveFloat } from '../../../../../utils/constants/regExp.constants'
import UITextTitleMedium from '../../../../../ui/UIText/UITextTitleMedium'
import FormSwitch from '../../../FormComponents/FormSwitch/FormSwitch'

const EditDeviceFormTriggers = () => {
  const isLoading = useSelector((state) => state.devices.editDevice.isLoading)

  return (
    <>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <UITextTitleMedium text="Impact" />
          <FormSwitch id="imapctTriggerEnabled" name="impactTriggerEnabled" disabled={isLoading} />
        </Stack>
        <Divider sx={{ mt: 1 }} />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          type="text"
          id="accelerationValue"
          label="Sensitivity"
          name="accelerationValue"
          disabled={isLoading}
          inputProps={{
            inputMode: 'numeric',
          }}
          regExp={onlyPositiveFloat}
          validateOnChange
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInput
          type="text"
          id="minutesBeforeTrigger"
          name="minutesBeforeTrigger"
          label="Interval before impact"
          disabled={isLoading}
          inputProps={{
            inputMode: 'numeric',
          }}
          regExp={onlyPositiveFloat}
          suffix="minutes"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInput
          type="text"
          id="minutesAfterTrigger"
          name="minutesAfterTrigger"
          label="Interval after impact"
          disabled={isLoading}
          inputProps={{
            inputMode: 'numeric',
          }}
          regExp={onlyPositiveFloat}
          suffix="minutes"
        />
      </Grid>
    </>
  )
}

export default EditDeviceFormTriggers
