import React, { useEffect } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Layout from '../components/Layout/Layout'
import { systemAdminRoutes, adminRoutes, publicRoutes } from './routes'
import { generateReactKey } from '../utils/helpers/reactKey.helper'
import { ROLES } from '../utils/constants/global.constants'

const AppRouter = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const role = useSelector((state) => state.auth.user?.role)

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />} path="/">
          {isAuthenticated ? (
            <>
              {role && role === ROLES.SYSTEM_ADMIN
                ? systemAdminRoutes.map((route) => {
                    return (
                      <Route key={generateReactKey()} path={route.path} element={route.element} />
                    )
                  })
                : null}
              {role && role === ROLES.ADMIN
                ? adminRoutes.map((route) => {
                    return (
                      <Route key={generateReactKey()} path={route.path} element={route.element} />
                    )
                  })
                : null}
            </>
          ) : (
            publicRoutes.map((route) => {
              return <Route key={generateReactKey()} path={route.path} element={route.element} />
            })
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
