import React from 'react'
import { Box, Stack, TextField } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'
import UiTextHeadlineLarge from '../../ui/UIText/UITextHeadlineLarge'
import FormInput from '../../components/Forms/FormComponents/FormInput/FormInput'
import { demoCredentials } from '../../utils/constants/demo.constants'

const Settings = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        flex: 1,
        p: '30px',
      }}
    >
      <Stack gap="30px">
        <UiTextHeadlineLarge text="Settings" />
        <TextField label="email" value={demoCredentials.email} />
      </Stack>
    </Box>
  )
}

export default Settings
