export const DEMO_TOKEN =
  'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIzIiwiY3VzdG9tZXJJZCI6IjEiLCJzdWJUeXBlIjoidXNlciIsInRva2VuVHlwZSI6ImFjY2Vzcy10b2tlbiIsImV4cCI6MTcwODU0MDMzMSwiaWF0IjoxNzA4NTMzMTMxfQ.-nWzAJziM89-4meJMCy56hvIyfXRhkUoh3gdgfAoNn0'
export const demoUsers = ['mail@ai.com', 'andrey@test.com', 'john.test@test.com', 'bob@test.com']

export const demoCredentials = {
  admin: {
    username: 'admin',
    password: 'string',
  },
  systemAdmin: {
    username: 'systemAdmin',
    password: 'string',
  },
}

export const demoDevices = [
  {
    id: 1,
    name: 'Camera n35',
    online: true,
    status: 'recording',
    recordSource: 'accel',
    uploadStatus: 'no upload',
    uptime: '00:13:23',
    lastOnline: '07.06.2023 00:13:23',
  },
  {
    id: 2,
    name: 'Camera n21',
    online: false,
    status: 'recording',
    recordSource: 'accel',
    uploadStatus: 'no upload',
    uptime: '00:13:23',
    lastOnline: '07.06.2023 00:13:23',
  },
  {
    id: 3,
    name: 'Camera n03',
    online: false,
    status: 'recording',
    recordSource: 'accel',
    uploadStatus: 'no upload',
    uptime: '00:13:23',
    lastOnline: '07.06.2023 00:13:23',
  },
  {
    id: 4,
    name: 'Camera n29',
    online: false,
    status: 'recording',
    recordSource: 'accel',
    uploadStatus: 'no upload',
    uptime: '00:13:23',
    lastOnline: '07.06.2023 00:13:23',
  },
]

export const demoTrackingHistoryTableHead = [
  {
    name: 'Reason',
    endAdornment: 'up-icon-button',
  },
  {
    name: 'Start record time',
  },
  {
    name: 'Duration',
  },
  {
    name: 'Storage',
  },
  {
    name: '',
  },
]
export const demoTrackingHistory = [
  {
    cells: [
      {
        type: 'text',
        value: 'Request',
      },
      {
        type: 'text',
        value: '07.06.2023 00:13:23',
      },
      {
        type: 'text',
        value: '10',
      },
      {
        type: 'text',
        value: 'Server',
      },
      {
        type: 'action-button',
        value: 'upload',
      },
    ],
  },
  {
    cells: [
      {
        type: 'text',
        value: 'Accelerometer',
      },
      {
        type: 'text',
        value: '07.06.2023 00:13:23',
      },
      {
        type: 'text',
        value: '10',
      },
      {
        type: 'text',
        value: 'Device',
      },
      {
        type: 'action-button',
        value: 'play',
      },
    ],
  },
  {
    cells: [
      {
        type: 'text',
        value: 'Motion',
      },
      {
        type: 'text',
        value: '07.06.2023 00:13:23',
      },
      {
        type: 'text',
        value: '10',
      },
      {
        type: 'text',
        value: 'Server',
      },
      {
        type: 'action-button',
        value: 'upload',
      },
    ],
  },
]
