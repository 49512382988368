import React, { useEffect, useRef } from 'react'
import { Box, Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import QRCodeLib from 'qrcode.react'
import { getDeviceRegisterToken, getDevices } from '../../../store/slices/devices/devices.actions'
import UITextHeadlineLargeBold from '../../../ui/UIText/UITextHeadlineLargeBold'
import UITextBodyMedium from '../../../ui/UIText/UITextBodyMedium'
import UIButtonAccentSmall from '../../../ui/UIButton/UIButtonAccentSmall'
import { downloadQRCode } from '../../../utils/helpers/files.helper'
import { COLOR } from '../../../utils/constants/color.constants'

const AddDeviceQRScan = ({ setOpen }) => {
  const registerToken = useSelector((state) => state.devices.addDevice.token)
  const dispatch = useDispatch()

  const QRCodeRef = useRef(null)

  useEffect(() => {
    return () => {
      dispatch(getDevices({}))
    }
  }, [])

  return (
    <Stack justifyContent="center" alignItems="center" gap="20px">
      <Box
        ref={QRCodeRef}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <QRCodeLib value={registerToken} size={256} />
      </Box>
      <Stack gap="10px">
        <UITextHeadlineLargeBold
          text="Scan QR-code"
          sx={{ fontWeight: 700, textAlign: 'center' }}
        />
        <UITextBodyMedium
          text={
            <span>
              Scan QR-code on the mobile application
              <br /> to register a new device
            </span>
          }
          sx={{
            color: COLOR.GRAY_MEDIUM_LIGHT,
            fontWeight: 300,
            textAlign: 'center',
            fontSize: '16px',
          }}
        />
      </Stack>
      <Stack gap="10px" sx={{ width: '100%' }}>
        {registerToken ? (
          <UIButtonAccentSmall fullWidth onClick={() => downloadQRCode(QRCodeRef.current)}>
            Download QR-code
          </UIButtonAccentSmall>
        ) : null}
        <UIButtonAccentSmall fullWidth onClick={() => setOpen(false)}>
          Done
        </UIButtonAccentSmall>
      </Stack>
    </Stack>
  )
}

export default AddDeviceQRScan
