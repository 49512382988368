import React from 'react'
import { Form, Formik } from 'formik'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import FormInput from '../../FormComponents/FormInput/FormInput'
import UIButtonMediumDanger from '../../../../ui/UIButton/UIButtomMediumDanger'
import UIButtonMedium from '../../../../ui/UIButton/UIButtonMedium'
import { getApiKeys, updateApiKey } from '../../../../store/slices/apiKeys/apiKeys.actions'
import { editApiKeyFormValidationSchema } from './editApiKeyForm.helpers'

const EditApiKeyForm = ({ onClose, data }) => {
  const isLoading = useSelector((state) => state.apiKeys.update.isLoading)
  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    const res = await dispatch(updateApiKey({ data: values, id: data?.id }))
    if (updateApiKey.fulfilled.match(res)) {
      dispatch(getApiKeys())
      onClose()
    }
  }

  return (
    <Formik
      initialValues={{
        label: data?.label ?? '',
      }}
      onSubmit={handleSubmit}
      validationSchema={editApiKeyFormValidationSchema}
      enableReinitialize
    >
      {(props) => (
        <Form>
          <Stack gap="30px">
            <FormInput
              type="text"
              id="label"
              label="Label"
              name="label"
              disabled={isLoading}
              required
            />
            <Stack direction="row" gap="10px" sx={{ mt: '10px' }}>
              <UIButtonMediumDanger onClick={onClose} fullWidth>
                Cancel
              </UIButtonMediumDanger>
              <UIButtonMedium
                fullWidth
                type="submit"
                disabled={isLoading || (props.touched && !props.isValid)}
              >
                Confirm
              </UIButtonMedium>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default EditApiKeyForm
