import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getDevices } from '../../../store/slices/devices/devices.actions'
import RecordersList from './RecordersList'
import RecordersHeader from './RecordersHeader'
import { COLOR } from '../../../utils/constants/color.constants'

const RecordersMain = () => {
  const { filters, selectedDevice, pagination, searchLine } = useSelector((state) => state.devices)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDevices())
  }, [filters.currentStatus, filters.globalStatus, pagination.size, pagination.page, searchLine])

  return (
    <Box
      className={[selectedDevice ? 'collapsed' : '', 'disable-scrollbar'].join(' ')}
      sx={{
        flex: 2,
        maxWidth: '800px',
        borderRight: '1px solid',
        borderColor: COLOR.GRAY_LIGHT,
        borderRadius: '4px',
        transition: '0.3s all ease-in-out',
        '&.collapsed': {
          transition: '0.3s all ease-in-out',
          maxWidth: '550px',
        },
      }}
    >
      <RecordersHeader />
      <RecordersList />
    </Box>
  )
}

export default RecordersMain
