import React from 'react'
import { Typography } from '@mui/material'

const UiTextBodyMedium = ({ text, sx, ...props }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: 'normal',
        letterSpacing: '0.5px',
        ...sx,
      }}
      {...props}
    >
      {text}
    </Typography>
  )
}

export default UiTextBodyMedium
