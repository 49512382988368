import { deleteCustomer } from '../customers.actions'

export default (builder) => {
  builder
    .addCase(deleteCustomer.pending, (state) => {
      state.deleteCustomer.isLoading = true
    })
    .addCase(deleteCustomer.fulfilled, (state, action) => {
      state.deleteCustomer.isLoading = false
      state.deleteCustomer.error = null
      state.customers.find((item) => item.id === action.payload.id).status = 'DELETED'
    })
    .addCase(deleteCustomer.rejected, (state, action) => {
      state.deleteCustomer.isLoading = false
      state.deleteCustomer.error = action.payload
    })
}
