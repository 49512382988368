import { createAsyncThunk } from '@reduxjs/toolkit'
import authApiService from '../../../api/authApiService/authApiService'

export const signIn = createAsyncThunk('auth/signIn', async (credentials, { rejectWithValue }) => {
  try {
    const response = await authApiService.authToken(credentials)
    return response.data
  } catch (e) {
    return rejectWithValue(e?.response?.data?.message)
  }
})

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authApiService.getUserInfo()
      return response.data
    } catch (e) {
      return rejectWithValue(e?.response?.data?.message)
    }
  },
)
