import React from 'react'
import { Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import UIButtonMediumDanger from '../../../../ui/UIButton/UIButtomMediumDanger'
import UIButtonMedium from '../../../../ui/UIButton/UIButtonMedium'
import FormInput from '../../FormComponents/FormInput/FormInput'
import { registerDeviceValidationSchema } from './registerDeviceForm.constants'
import UITextHeadlineLargeBold from '../../../../ui/UIText/UITextHeadlineLargeBold'
import { getDeviceRegisterToken } from '../../../../store/slices/devices/devices.actions'

const RegisterDeviceForm = ({ onClose }) => {
  const isLoading = useSelector((state) => state.devices.addDevice.isLoadingToken)
  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    dispatch(getDeviceRegisterToken({ data: values }))
  }

  return (
    <Stack gap="30px">
      <UITextHeadlineLargeBold text="Add new recorder" sx={{ fontWeight: 700 }} />
      <Formik
        initialValues={{
          name: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={registerDeviceValidationSchema}
      >
        {(props) => (
          <Form>
            <Stack gap="30px">
              <FormInput
                required
                type="text"
                id="name"
                label="Recorder name"
                name="name"
                disabled={isLoading}
              />
              <Stack direction="row" gap="10px">
                <UIButtonMediumDanger onClick={onClose} fullWidth>
                  Cancel
                </UIButtonMediumDanger>
                <UIButtonMedium
                  fullWidth
                  type="submit"
                  disabled={isLoading || (props.touched && !props.isValid)}
                >
                  Add
                </UIButtonMedium>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  )
}

export default RegisterDeviceForm
