import React from 'react'
import { Tooltip } from '@mui/material'

const UITooltip = ({ text, children }) => {
  return (
    <Tooltip title={text}>
      <span>{children}</span>
    </Tooltip>
  )
}

export default UITooltip
