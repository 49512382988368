import React from 'react'
import { Stack } from '@mui/material'
import UIModal from '../../../ui/UIModal/UIModal'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import AddAdminForm from '../../Forms/FormTemplates/AddAdminForm/AddAdminForm'

const AddAdminModal = ({ open, setOpen }) => {
  return (
    <UIModal wrapperStyles={{ width: '500px' }} open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text="Add a new admin" />
        <AddAdminForm onClose={() => setOpen(false)} />
      </Stack>
    </UIModal>
  )
}

export default AddAdminModal
