import { deleteApiKey } from '../apiKeys.actions'

export default (builder) => {
  builder
    .addCase(deleteApiKey.pending, (state) => {
      state.delete.isLoading = true
    })
    .addCase(deleteApiKey.fulfilled, (state) => {
      state.delete.isLoading = false
      state.delete.error = null
    })
    .addCase(deleteApiKey.rejected, (state, action) => {
      state.delete.isLoading = false
      state.delete.error = action.payload
    })
}
