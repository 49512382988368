import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { currentSegmentStatusEnums, eventTypes } from '../utils/constants/enums.constants'
import {
  setDeviceCurrentStatus,
  setDeviceConfiguration,
  setDevicePreview,
} from '../store/slices/devices/devices.reducer'
import {
  setCurrentSegments,
  setCurrentSegmentStatus,
  setUploadedSegment,
} from '../store/slices/segments/segments.reducer'

const deviceSocketEndpoints = {
  events: (id) => `/topic/device/${id}/events`,
  segments: (id) => `/topic/segments/${id}`,
}

const UseDeviceSocket = ({ device }) => {
  const socketClient = useSelector((state) => state.auth.socketClient)
  const dispatch = useDispatch()

  const [subscribeToEvents, setSubscribeToEvents] = useState(false)
  const [eventMessage, setEventMessage] = useState(null)
  const [eventSubscription, setEventSubscription] = useState(null)
  const [subscribeToSegments, setSubscribeToSegments] = useState(false)
  const [segmentsMessage, setSegmentsMessage] = useState(null)
  const [segmentsSubscription, setSegmentsSubscription] = useState(null)

  const handleSubscribeToEvents = () => {
    const subscription = socketClient.subscribe(
      deviceSocketEndpoints.events(device?.id),
      (message) => {
        setEventMessage(JSON.parse(message?.body))
      },
      { id: `events-${device?.id}` },
    )
    setEventSubscription(subscription)
  }

  const handleEventMessageAction = () => {
    if (eventMessage?.event === eventTypes.ONLINE || eventMessage?.event === eventTypes.OFFLINE) {
      dispatch(
        setDeviceCurrentStatus({
          id: device?.id,
          status: eventMessage?.event,
        }),
      )
    }
    if (eventMessage?.event === eventTypes.DEVICE_NEW_CONFIGURATION) {
      dispatch(
        setDeviceConfiguration({
          id: device?.id,
          body: eventMessage?.body,
        }),
      )
    }
    if (eventMessage?.event === eventTypes.START_UPLOADING_SEGMENT) {
      dispatch(
        setCurrentSegmentStatus({
          segmentName: eventMessage?.body?.segmentName,
          status: currentSegmentStatusEnums.UPLOADING,
        }),
      )
    }
    if (eventMessage?.event === eventTypes.SEGMENT_UPLOADED) {
      dispatch(
        setCurrentSegmentStatus({
          segmentName: eventMessage?.body?.segmentName,
          status: currentSegmentStatusEnums.UPLOADED,
        }),
      )
    }
    if (eventMessage?.event === eventTypes.DEVICE_PREVIEW) {
      dispatch(
        setDevicePreview({
          id: device?.id,
          body: eventMessage?.body?.base64Photo,
        }),
      )
    }
  }

  const handleSubscribeToSegments = () => {
    const subscription = socketClient.subscribe(
      deviceSocketEndpoints.segments(device?.id),
      (message) => {
        setSegmentsMessage(JSON.parse(message?.body))
      },
      { id: `segments-${device?.id}` },
    )
    setSegmentsSubscription(subscription)
  }

  const handleSegmentsMessageAction = () => {
    dispatch(setCurrentSegments(segmentsMessage?.segments))
  }

  useEffect(() => {
    return () => {
      if (segmentsSubscription) {
        segmentsSubscription.unsubscribe()
      }
    }
  }, [segmentsSubscription])

  useEffect(() => {
    if (segmentsMessage) {
      handleSegmentsMessageAction()
    }
  }, [segmentsMessage])

  useEffect(() => {
    if (subscribeToSegments) {
      handleSubscribeToSegments()
    } else if (!subscribeToSegments && segmentsSubscription) {
      setSegmentsSubscription(null)
    }
  }, [subscribeToSegments])

  useEffect(() => {
    if (eventMessage) {
      handleEventMessageAction()
    }
  }, [eventMessage])

  useEffect(() => {
    return () => {
      if (eventSubscription) {
        eventSubscription.unsubscribe()
      }
    }
  }, [eventSubscription])

  useEffect(() => {
    if (subscribeToEvents) {
      handleSubscribeToEvents()
    }
  }, [subscribeToEvents])

  return {
    eventMessage,
    subscribeToEvents,
    setSubscribeToEvents,
    setSubscribeToSegments,
  }
}

export default UseDeviceSocket
