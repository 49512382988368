import { createSlice } from '@reduxjs/toolkit'
import getTriggersBuilder from './builders/getTriggers.builder'
import getSegmentGroupBuilder from './builders/getSegmentGroup.builder'
import getSegmentVideoBuilder from './builders/getSegmentVideo.builder'
import {
  getPreferenceKey,
  preferenceKeys,
  setPreferenceKey,
} from '../../../utils/helpers/localStorage.helpers'

const initialState = {
  triggers: [],
  isLoading: false,
  error: null,
  pagination: {
    page: 0,
    size: getPreferenceKey(preferenceKeys.TRIGGERS_PER_PAGE) ?? 10,
    total: 0,
    sort: '',
  },
  filters: {
    deviceId: null,
  },
  selectedSegmentGroup: {
    data: null,
    isLoading: false,
    error: null,
    segments: [],
  },
  video: {
    segmentId: null,
    loading: false,
    error: null,
    url: null,
    playing: false,
  },
}

const triggersSlice = createSlice({
  name: 'triggersReducer',
  initialState,
  reducers: {
    setTriggersPage: (state, action) => {
      state.pagination.page = action.payload
    },
    setTriggersSize: (state, action) => {
      state.pagination.size = action.payload
      state.pagination.page = 0
      setPreferenceKey(preferenceKeys.TRIGGERS_PER_PAGE, action.payload)
    },
    setTriggersSort: (state, action) => {
      state.pagination.sort = action.payload
      state.pagination.page = 0
    },
    setSelectedSegmentGroup: (state, action) => {
      state.selectedSegmentGroup.data = action.payload
    },
    setSegmentGroupVideo: (state, action) => {
      state.video.segmentId = action.payload
    },
    resetTriggersData: (state) => {
      state.triggers = initialState.triggers
      state.error = initialState.error
      state.isLoading = initialState.isLoading
      state.pagination = initialState.pagination
      state.deviceId = initialState.deviceId
      state.selectedSegmentGroup = initialState.selectedSegmentGroup
      // state.video = initialState.video
    },
  },
  extraReducers: (builder) => {
    getTriggersBuilder(builder)
    getSegmentGroupBuilder(builder)
    getSegmentVideoBuilder(builder)
  },
})

export const {
  setTriggersPage,
  setTriggersSize,
  setTriggersSort,
  resetTriggersData,
  setSelectedSegmentGroup,
  setSegmentGroupVideo,
} = triggersSlice.actions

export default triggersSlice.reducer
