import axios from 'axios'
import {
  getRefreshToken,
  getToken,
  removeRefreshToken,
  removeToken,
  setRefreshToken,
  setToken,
} from '../utils/helpers/auth.helper'

export const BASE_URL = process.env.REACT_APP_BASE_API_URL

let isRefreshingToken = false
let refreshSubscribers = []

function processSubscribers(token) {
  refreshSubscribers.forEach((callback) => callback(token))
  refreshSubscribers = []
}

const $api = axios.create({
  headers: {
    'Content-type': 'application/json',
  },
  baseURL: BASE_URL,
})

$api.interceptors.request.use((config) => {
  if (getToken()) config.headers.Authorization = `Bearer ${getToken()}`
  return config
})

$api.interceptors.response.use(
  (config) => {
    return config
  },
  async (error) => {
    const originalRequest = error?.config
    if (error.response?.status === 401 && error.config && !error.config?._isRetry) {
      if (isRefreshingToken) {
        return new Promise((resolve) => {
          refreshSubscribers.push((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve($api(originalRequest))
          })
        })
      }
      isRefreshingToken = true
      originalRequest._isRetry = true
      removeToken()
      try {
        const refresh_token = getRefreshToken()
        if (refresh_token) {
          const response = await axios.post(`${BASE_URL}auth/refresh-token`, {
            refreshToken: refresh_token,
            secure: true,
          })
          setToken(response.data.accessToken)
          setRefreshToken(response.data.refreshToken)
          processSubscribers(response.data.accessToken)
          isRefreshingToken = false
          return $api.request(originalRequest)
        }
        return Promise.reject(error)
      } catch (e) {
        removeRefreshToken()
        isRefreshingToken = false
        refreshSubscribers = []
        return Promise.reject(e)
      }
    }
    return Promise.reject(error)
  },
)

export default $api
