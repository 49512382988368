import { getApiKeys } from '../apiKeys.actions'

export default (builder) => {
  builder
    .addCase(getApiKeys.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    .addCase(getApiKeys.fulfilled, (state, action) => {
      state.isLoading = false
      state.apiKeys.content = action.payload?.content
      state.apiKeys.totalElements = action.payload?.totalElements
    })
    .addCase(getApiKeys.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
