import { toast } from 'react-toastify'
import { getDeviceRegisterToken } from '../devices.actions'

export default (builder) => {
  builder
    .addCase(getDeviceRegisterToken.pending, (state) => {
      state.addDevice.isLoadingToken = true
    })
    .addCase(getDeviceRegisterToken.fulfilled, (state, action) => {
      state.addDevice.token = action.payload.token
      state.addDevice.isLoadingToken = false
      state.addDevice.error = null
    })
    .addCase(getDeviceRegisterToken.rejected, (state, action) => {
      state.addDevice.isLoadingToken = false
      state.addDevice.error = action.payload
      toast.error(action.payload.error)
    })
}
