export const UISize = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
}

export const VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  ATTENTION: 'attention',
  CANCEL: 'cancel',
}
