export const formatApiKey = (apiKey) => {
  if (!apiKey) return ''
  const start = apiKey.substring(0, 10)
  const end = apiKey.substring(apiKey.length - 10)
  return `${start}...${end}`
}

export const cleanObject = (obj) => {
  const cleanedObj = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      if (value !== '' && value !== null && value !== undefined) {
        cleanedObj[key] = value
      }
    }
  }
  return cleanedObj
}

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (e) {
    console.log(e)
    return false
  }
}
