import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Stack } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setSelectedDevice } from '../../store/slices/devices/devices.reducer'
import UITextBodyMedium from '../../ui/UIText/UITextBodyMedium'
import UITextLabelLarge from '../../ui/UIText/UITextLabelLarge'
import UIButtonLinkSmall from '../../ui/UIButton/UIButtonLinkSmall'
import devicesApiService from '../../api/devicesApiService/devicesApiService'
import { COLOR } from '../../utils/constants/color.constants'

const ImpactToast = ({ closeToast, toastProps, deviceId, eventData }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [device, setDevice] = useState(null)

  const getDevice = async () => {
    setLoading(true)
    try {
      const response = await devicesApiService.getDeviceById(deviceId)
      setDevice(response.data)
    } catch (e) {
      closeToast()
    } finally {
      setLoading(false)
    }
  }

  const goToDevice = () => {
    dispatch(
      setSelectedDevice({
        ...device,
        event: eventData,
      }),
    )
  }

  useEffect(() => {
    getDevice()
  }, [])

  return (
    <Box>
      <UITextLabelLarge text="Impact occurred!" />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: '5px',
          }}
        >
          <CircularProgress sx={{ color: COLOR.ORANGE }} />
        </Box>
      ) : (
        <Stack gap="10px">
          <UITextBodyMedium>
            {device ? (
              <>
                Recorder{' '}
                <UIButtonLinkSmall onClick={() => goToDevice()}>
                  &quot;{device?.name}&quot;
                </UIButtonLinkSmall>{' '}
                was triggered by a sudden shift
              </>
            ) : (
              `Recorder with id ${deviceId} was triggered by a sudden shift`
            )}
          </UITextBodyMedium>
        </Stack>
      )}
    </Box>
  )
}

export default ImpactToast
