import { addAdmin } from '../customers.actions'

export default (builder) => {
  builder
    .addCase(addAdmin.pending, (state) => {
      state.addAdmin.isLoading = true
    })
    .addCase(addAdmin.fulfilled, (state) => {
      state.addAdmin.isLoading = false
      state.addAdmin.error = null
    })
    .addCase(addAdmin.rejected, (state, action) => {
      state.addAdmin.error = action.payload
      state.addAdmin.isLoading = false
    })
}
