import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  deleteCustomer,
  getCustomers,
  recoverCustomer,
} from '../../store/slices/customers/customers.actions'
import UIPaper from '../../ui/UIPaper/UIPaper'
import UITable from '../../ui/UITable/UITable'
import UITextHeadlineLarge from '../../ui/UIText/UITextHeadlineLarge'
import UIButtonAccentSmall from '../../ui/UIButton/UIButtonAccentSmall'
import UIIconButtonGoTo from '../../ui/UIButton/UIIconButton/UIIconButtonGoTo'
import UIIconButtonRevert from '../../ui/UIButton/UIIconButton/UIIconButtonRevert'
import UIIconButtonBlock from '../../ui/UIButton/UIIconButton/UIIconButtonBlock'
import DeleteModal from '../../components/Modals/ConfirmationModals/DeleteModal'
import AddCustomerModal from '../../components/Modals/AddCustomerModal/AddCustomerModal'
import RecoverModal from '../../components/Modals/ConfirmationModals/RecoverModal'
import { getCustomersTableData } from '../../utils/helpers/tables.helper'
import { COLOR } from '../../utils/constants/color.constants'
import { customersTableHead } from '../../utils/constants/tables.constants'

const Customers = () => {
  const customers = useSelector((state) => state.customers.customers)
  const isDeletionLoading = useSelector((state) => state.customers.deleteCustomer.isLoading)
  const isRecoveryLoading = useSelector((state) => state.customers.recoverCustomer.isLoading)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [addCustomerModal, setAddCustomerModal] = useState(false)
  const [deleteCustomerModal, setDeleteCustomerModal] = useState(false)
  const [recoverCustomerModal, setRecoverCustomerModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const renderActionItems = (row) => {
    return (
      <Box
        sx={{
          background: 'white',
          height: '100%',
          display: 'flex',
          gap: '5px',
        }}
      >
        {row?.initialData?.status === 'ACTIVE' ? (
          <UIIconButtonBlock
            title="Block"
            onClick={() => {
              setModalData(row?.initialData)
              setDeleteCustomerModal(true)
            }}
          />
        ) : (
          <UIIconButtonRevert
            title="Unblock"
            onClick={() => {
              setModalData(row?.initialData)
              setRecoverCustomerModal(true)
            }}
          />
        )}
        <UIIconButtonGoTo onClick={() => navigate(`/customers/${row?.initialData?.id}`)} />
      </Box>
    )
  }

  const handleDeleteCustomer = () => {
    dispatch(deleteCustomer({ id: modalData?.id }))
      .unwrap()
      .then(() => {
        toast.success('Customer has been blocked successfully')
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => setDeleteCustomerModal(false))
  }

  const handleRecoverCustomer = () => {
    dispatch(recoverCustomer({ id: modalData?.id }))
      .unwrap()
      .then(() => {
        toast.success('Customer has been recovered successfully')
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => setRecoverCustomerModal(false))
  }

  useEffect(() => {
    dispatch(getCustomers())
  }, [])

  return (
    <Box
      sx={{
        borderLeft: '1px solid',
        borderColor: COLOR.GRAY_EXTRA_LIGHT,
        flex: 1,
        height: '100vh',
        overflow: 'auto',
        px: '30px',
        py: '30px',
      }}
    >
      <Stack gap="30px">
        <UITextHeadlineLarge text="Customers" />
        <UIPaper>
          <Stack direction="row" justifyContent="flex-end">
            <UIButtonAccentSmall
              sx={{ display: 'flex', gap: '5px' }}
              onClick={() => setAddCustomerModal(true)}
            >
              <Add sx={{ fill: COLOR.WHITE }} /> Add new customer
            </UIButtonAccentSmall>
          </Stack>
          <Box
            sx={{
              mt: '30px',
              borderRadius: '6px',
              overflow: 'hidden',
              border: '1px solid',
              borderColor: COLOR.GRAY_EXTRA_LIGHT,
              boxShadow: '0 0 12px 0 rgba(0,0,0,.1)',
            }}
          >
            <UITable
              head={customersTableHead}
              data={getCustomersTableData(customers)}
              renderActionItems={renderActionItems}
              emptyText="No customers have been added yet"
              variant="rounded"
            />
          </Box>
        </UIPaper>
      </Stack>
      <AddCustomerModal open={addCustomerModal} setOpen={setAddCustomerModal} />
      <DeleteModal
        open={deleteCustomerModal}
        setOpen={setDeleteCustomerModal}
        data={modalData}
        handleSubmit={handleDeleteCustomer}
        isLoading={isDeletionLoading}
      />
      <RecoverModal
        open={recoverCustomerModal}
        setOpen={setRecoverCustomerModal}
        data={modalData}
        handleSubmit={handleRecoverCustomer}
        isLoading={isRecoveryLoading}
      />
    </Box>
  )
}

export default Customers
