import { editDevice } from '../devices.actions'

export default (builder) => {
  builder
    .addCase(editDevice.pending, (state) => {
      state.editDevice.isLoading = true
    })
    .addCase(editDevice.fulfilled, (state, action) => {
      if (action.payload?.name) {
        const updateDevice = state.devices.find((item) => item?.id === action.payload?.deviceId)
        if (updateDevice) {
          updateDevice.name = action?.payload?.name
        }
        if (state.selectedDevice?.id === action.payload?.deviceId) {
          state.selectedDevice.name = action.payload?.name
        }
      }
      state.editDevice.isLoading = false
      state.editDevice.error = null
    })
    .addCase(editDevice.rejected, (state, action) => {
      state.editDevice.error = action.payload
      state.editDevice.isLoading = false
    })
}
