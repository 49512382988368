import React from 'react'
import { Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import UIModal from '../../../ui/UIModal/UIModal'
import UITextHeadlineLargeBold from '../../../ui/UIText/UITextHeadlineLargeBold'
import EditDeviceForm from '../../Forms/FormTemplates/EditDeviceForm/EditDeviceForm'

const EditDeviceModal = ({ open, setOpen }) => {
  const device = useSelector((state) => state.devices.selectedDevice)

  return (
    <UIModal open={open} setOpen={setOpen} wrapperStyles={{ width: '650px' }}>
      <Stack gap="30px">
        <UITextHeadlineLargeBold
          text={`${device?.name ? `"${device?.name}"` : `Camera n${device.id}`} settings`}
        />
        <EditDeviceForm onClose={() => setOpen(false)} />
      </Stack>
    </UIModal>
  )
}

export default EditDeviceModal
