import React, { useEffect, useState } from 'react'
import { Box, TablePagination } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getSavedSegments } from '../../../store/slices/segments/segments.actions'
import {
  setSavedSegmentsSize,
  setSavedSegmentsPage,
} from '../../../store/slices/segments/segments.reducer'
import { getSegmentGroup, getTriggers } from '../../../store/slices/triggers/triggers.actions'
import {
  resetTriggersData,
  setSelectedSegmentGroup,
  setTriggersPage,
  setTriggersSize,
  setTriggersSort,
} from '../../../store/slices/triggers/triggers.reducer'
import { demoTrackingHistoryTableHead } from '../../../utils/constants/demo.constants'
import UITable from '../../../ui/UITable/UITable'
import UITabs from '../../../ui/UITabs/UITabs'
import UITab from '../../../ui/UITabs/UITab'
import {
  getCurrentSegmentsTableData,
  getDeviceTriggersTableData,
  getSavedSegmentsTableData,
} from '../../../utils/helpers/tables.helper'
import SegmentInfoModal from '../../../components/Modals/SegmentInfoModal/SegmentInfoModal'
import {
  currentSegmentsTableHead,
  deviceTriggersTableHead,
} from '../../../utils/constants/tables.constants'
import SavedSegmentActions from './SegmentActions/SavedSegmentActions'
import CurrentSegmentsActions from './SegmentActions/CurrentSegmentsActions'
import { COLOR } from '../../../utils/constants/color.constants'
import TriggersActions from './SegmentActions/TriggersActions'
import SegmentsSideTable from './SegmentsSideTable'

const RecorderSegments = () => {
  const device = useSelector((state) => state.devices.selectedDevice)
  const savedSegments = useSelector((state) => state.segments.savedSegments)
  const pagination = useSelector((state) => state.segments.savedSegmentsPagination)
  const currentSegments = useSelector((state) => state.segments.currentSegments)

  const triggers = useSelector((state) => state.triggers.triggers)
  const triggersPagination = useSelector((state) => state.triggers.pagination)
  const isLoadingTriggers = useSelector((state) => state.triggers.isLoading)
  const triggersFilters = useSelector((state) => state.triggers.filters)

  const dispatch = useDispatch()

  const [tab, setTab] = useState(0)
  const [segmentInfoModal, setSegmentInfoModal] = useState(false)
  const [segmentInfoModalData, setSegmentInfoModalData] = useState(null)
  const [openSideTable, setOpenSideTable] = useState(false)

  const handleShowSegmentsGroup = (segmentGroup) => {
    setOpenSideTable(true)
    dispatch(setSelectedSegmentGroup(segmentGroup))
    dispatch(getSegmentGroup({ segmentGroupId: segmentGroup?.id }))
  }

  useEffect(() => {
    if (device?.id && tab === 1) {
      dispatch(getSavedSegments({ deviceId: device.id }))
    }
  }, [device?.id, tab, pagination.size, pagination.page])

  useEffect(() => {
    if (device?.id !== triggersFilters.deviceId) {
      dispatch(resetTriggersData())
    }
    if (device?.id && tab === 2) {
      dispatch(getTriggers({ deviceId: device?.id }))
    }
    if (tab !== 2) {
      setOpenSideTable(false)
    }
  }, [device?.id, tab, triggersPagination.size, triggersPagination.page, triggersPagination.sort])

  useEffect(() => {
    return () => {
      dispatch(resetTriggersData())
    }
  }, [])

  return (
    <Box sx={{ position: 'relative', pb: '52px' }}>
      <UITabs value={tab} onChange={(e, value) => setTab(value)}>
        <UITab label="Phone storage" />
        <UITab label="Server storage" />
        <UITab label="History" />
      </UITabs>
      <Box
        sx={{
          height: 'calc(100vh - 542px)',
          minHeight: '100px',
          overflow: 'auto',
        }}
      >
        {tab === 0 ? (
          <UITable
            head={currentSegmentsTableHead}
            data={getCurrentSegmentsTableData(currentSegments)}
            emptyText="Storage is empty"
            renderActionItems={(data) => <CurrentSegmentsActions data={data} />}
          />
        ) : tab === 1 ? (
          <>
            <UITable
              head={demoTrackingHistoryTableHead}
              data={getSavedSegmentsTableData(savedSegments)}
              emptyText="Storage is empty"
              renderActionItems={(data) => (
                <SavedSegmentActions
                  data={data}
                  setSegmentInfoModal={setSegmentInfoModal}
                  setSegmentInfoModalData={setSegmentInfoModalData}
                />
              )}
            />
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={pagination.total}
              page={pagination.page}
              rowsPerPage={pagination.size}
              onPageChange={(e, newPage) => dispatch(setSavedSegmentsPage(newPage))}
              onRowsPerPageChange={(e) => dispatch(setSavedSegmentsSize(e.target.value))}
              showFirstButton
              showLastButton
              labelRowsPerPage="Segments per page:"
              sx={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                left: 0,
                background: 'white',
                borderTop: '1px solid',
                borderColor: COLOR.GRAY_LIGHT,
              }}
            />
          </>
        ) : tab === 2 ? (
          <>
            <UITable
              head={deviceTriggersTableHead}
              data={getDeviceTriggersTableData(triggers)}
              emptyText="History is empty"
              renderActionItems={(data) => (
                <TriggersActions data={data} handleAction={handleShowSegmentsGroup} />
              )}
              sort={triggersPagination.sort}
              setSort={(value) => dispatch(setTriggersSort(value))}
            />
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={triggersPagination.total}
              page={triggersPagination.page}
              rowsPerPage={triggersPagination.size}
              onPageChange={(e, newPage) => dispatch(setTriggersPage(newPage))}
              onRowsPerPageChange={(e) => dispatch(setTriggersSize(e.target.value))}
              showFirstButton
              showLastButton
              labelRowsPerPage="Items per page:"
              sx={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                left: 0,
                background: 'white',
                borderTop: '1px solid',
                borderColor: COLOR.GRAY_LIGHT,
              }}
            />
          </>
        ) : null}
        <SegmentsSideTable
          open={openSideTable}
          setOpen={setOpenSideTable}
          setSegmentInfoModal={setSegmentInfoModal}
          setSegmentInfoModalData={setSegmentInfoModalData}
        />
      </Box>
      <SegmentInfoModal
        open={segmentInfoModal}
        setOpen={setSegmentInfoModal}
        data={segmentInfoModalData}
      />
    </Box>
  )
}

export default RecorderSegments
