export const COLOR = {
  BLACK: '#3D3D3D',
  GREEN: '#6cbb4b',
  GREEN_DARK: '#4d8f2e',
  GREEN_SUCCESS: '#219653',
  RED_ERROR: '#EB5757',
  RED_DARK: '#810c0c',
  WHITE: '#ffffff',
  OFF_WHITE: '#f1f1f1',
  GRAY_MEDIUM: '#667A8A',
  GRAY_LIGHT: '#D9D9D9',
  GRAY_EXTRA_LIGHT: '#F6F6F6',
  GRAY_MEDIUM_LIGHT: '#969696',
  GRAY_DARK: '#323232',
  ORANGE: '#ea8f1c',
  ORANGE_DARK: '#965f01',
  WHITE_TR_60: 'rgba(255, 255, 255, 0.6)',
}
