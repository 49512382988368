import React from 'react'
import { Typography } from '@mui/material'

const UiTextHeadlineSmall = ({ text, sx, ...props }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: 0,
        ...sx,
      }}
      {...props}
    >
      {text}
    </Typography>
  )
}

export default UiTextHeadlineSmall
