import React from 'react'
import { IconButton, Stack } from '@mui/material'
import { ViewListRounded } from '@mui/icons-material'
import { COLOR } from '../../../../utils/constants/color.constants'
import UITextBodyMedium from '../../../../ui/UIText/UITextBodyMedium'

const TriggersActions = ({ data, handleAction }) => {
  return (
    <Stack direction="row" gap="5px" sx={{ pr: '20px' }} alignItems="center">
      <IconButton onClick={() => handleAction(data?.initialData?.segmentGroup)}>
        <ViewListRounded
          sx={{
            fill: COLOR.GRAY_MEDIUM,
          }}
        />
      </IconButton>
      <UITextBodyMedium
        sx={{ color: COLOR.GRAY_MEDIUM }}
        text={`(${data?.initialData?.segmentGroup?.amountOfSegments})`}
      />
    </Stack>
  )
}

export default TriggersActions
