import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify'
import { getUserInfo, signIn } from '../auth.actions'
import { setRefreshToken, setToken } from '../../../../utils/helpers/auth.helper'
import { ROLES } from '../../../../utils/constants/global.constants'

export default (builder) => {
  builder
    .addCase(signIn.pending, (state, action) => {
      state.isLoading = true
    })
    .addCase(signIn.fulfilled, (state, action) => {
      const userInfo = jwtDecode(action.payload?.accessToken)
      state.user = {
        ...userInfo,
        role: userInfo?.customerId !== 'null' ? ROLES.ADMIN : ROLES.SYSTEM_ADMIN,
      }
      setToken(action.payload?.accessToken)
      setRefreshToken(action.payload?.refreshToken)
    })
    .addCase(signIn.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
    .addCase(getUserInfo.fulfilled, (state, action) => {
      state.user = action.payload
      state.isLoading = false
      state.isAuthenticated = true
      state.authChecked = true
    })
    .addCase(getUserInfo.rejected, (state, action) => {
      state.isLoading = false
      state.userInfoError = action.payload
      toast.error(action.payload)
      state.authChecked = true
    })
}
