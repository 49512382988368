import React from 'react'
import { Box } from '@mui/material'
import SidebarHeader from './SidebarHeader'
import SidebarMenu from './SidebarMenu'
import SidebarBottom from './SidebarBottom'
import { COLOR } from '../../../utils/constants/color.constants'

const Sidebar = () => {
  return (
    <Box
      sx={{
        width: '264px',
        minWidth: '264px',
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid',
        borderColor: COLOR.GRAY_LIGHT,
      }}
    >
      <SidebarHeader />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <SidebarMenu />
        <SidebarBottom />
      </Box>
    </Box>
  )
}

export default Sidebar
