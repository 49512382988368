import React, { useState } from 'react'
import { Box } from '@mui/material'
import { Add } from '@mui/icons-material'
import UIButtonAccentSmall from '../../../ui/UIButton/UIButtonAccentSmall'
import AddDeviceModal from '../../../components/Modals/AddDeviceModal/AddDeviceModal'
import { COLOR } from '../../../utils/constants/color.constants'

const AddRecorder = () => {
  const [addDeviceModalOpen, setAddDeviceModalOpen] = useState(false)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          px: '10px',
        }}
      >
        <UIButtonAccentSmall
          sx={{ display: 'flex', gap: '7px' }}
          onClick={() => {
            setAddDeviceModalOpen(true)
          }}
        >
          <Add sx={{ fill: COLOR.WHITE, fontSize: '18px' }} /> Add recorder
        </UIButtonAccentSmall>
      </Box>
      <AddDeviceModal open={addDeviceModalOpen} setOpen={setAddDeviceModalOpen} />
    </>
  )
}

export default AddRecorder
