import React from 'react'
import { useField } from 'formik'
import { Switch } from '@mui/material'
import { COLOR } from '../../../../utils/constants/color.constants'

const FormSwitch = ({ ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { setValue } = helpers

  const handleChange = ({ target }) => {
    setValue(target.checked)
  }

  return (
    <Switch
      sx={{
        '& .MuiSwitch-thumb': {
          backgroundColor: COLOR.GRAY_MEDIUM,
        },
        '& .MuiSwitch-track': {
          backgroundColor: COLOR.GRAY_MEDIUM_LIGHT,
        },
        '& .MuiSwitch-switchBase': {
          '&.Mui-checked': {
            color: COLOR.ORANGE,
            '& +.MuiSwitch-track': {
              backgroundColor: COLOR.ORANGE,
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: COLOR.ORANGE,
              opacity: 1,
            },
          },
        },
      }}
      name={field.name}
      checked={field.value}
      onChange={handleChange}
      {...props}
    />
  )
}

export default FormSwitch
