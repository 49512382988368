import React from 'react'
import { Button } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'

const UIButtonLinkPlain = ({ sx, children, ...props }) => {
  return (
    <Button
      sx={{
        py: '2px',
        px: '5px',
        position: 'relative',
        textTransform: 'none',
        fontSize: '16px',
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        color: COLOR.GRAY_MEDIUM,
        transition: '0.25s all ease-in-out',
        '&:hover': {
          color: COLOR.ORANGE,
          transition: '0.25s all ease-in-out',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default UIButtonLinkPlain
