import { getSegmentGroup } from '../triggers.actions'

export default (builder) => {
  builder
    .addCase(getSegmentGroup.pending, (state) => {
      state.selectedSegmentGroup.isLoading = true
    })
    .addCase(getSegmentGroup.fulfilled, (state, action) => {
      state.selectedSegmentGroup.isLoading = false
      state.selectedSegmentGroup.error = null
      state.selectedSegmentGroup.data = action.payload
    })
    .addCase(getSegmentGroup.rejected, (state, action) => {
      state.selectedSegmentGroup.isLoading = false
      state.selectedSegmentGroup.error = action.payload
    })
}
