import { getDevices } from '../devices.actions'

export default (builder) => {
  builder
    .addCase(getDevices.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getDevices.fulfilled, (state, action) => {
      const { events } = action.payload
      state.devices = action.payload.content.map((device) => {
        const matchEvent = events.find((e) => e?.deviceId.toString() === device?.id.toString())
        if (matchEvent) {
          return {
            ...device,
            event: matchEvent?.event,
          }
        }
        return device
      })
      state.pagination.total = action.payload.total
      state.isLoading = false
      state.error = null
    })
    .addCase(getDevices.rejected, (state, action) => {
      if (action.payload !== 'ERR_CANCELED') {
        state.isLoading = false
        state.error = action.payload
      }
    })
}
