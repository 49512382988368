export const globalStatusEnums = {
  ACTIVE: 'ACTIVE',
  BANNED: 'BANNED',
  WAITING_FOR_ACTIVATION: 'WAITING_FOR_ACTIVATION',
  DELETED: 'DELETED',
}

export const uploadModeEnums = {
  IMPACT: 'UPLOAD_TRIGGERED',
  UPLOAD_ALWAYS: 'UPLOAD_ALWAYS',
}

export const currentSegmentStatusEnums = {
  COMPLETED: 'COMPLETED',
  RECORDING: 'RECORDING',
  UPLOADED: 'UPLOADED',
  UPLOADING: 'UPLOADING',
}

export const globalStatusTypes = [
  {
    systemName: globalStatusEnums.ACTIVE,
    name: 'Active',
  },
  {
    systemName: globalStatusEnums.BANNED,
    name: 'Banned',
  },
  {
    systemName: globalStatusEnums.WAITING_FOR_ACTIVATION,
    name: 'Waiting for activation',
  },
  {
    systemName: globalStatusEnums.DELETED,
    name: 'Deleted',
  },
]

export const currentStatusTypes = [
  {
    systemName: 'OFFLINE',
    name: 'Offline',
  },
  {
    systemName: 'ONLINE',
    name: 'Online',
  },
]

export const recordingSourceTypes = [
  {
    systemName: 'RS_REAR',
    name: 'Rear',
  },
  {
    systemName: 'RS_FRONTAL',
    name: 'Frontal',
  },
]
export const uploadModeTypes = [
  {
    systemName: uploadModeEnums.IMPACT,
    name: 'Triggered',
  },
  {
    systemName: uploadModeEnums.UPLOAD_ALWAYS,
    name: 'Always',
  },
]

export const recordingModeTypes = [
  {
    systemName: 'RECORD_OFF',
    name: 'Off',
  },
  {
    systemName: 'RECORD_ALWAYS',
    name: 'Always',
  },
]

export const resolutionTypes = [
  {
    systemName: 'RES_480p',
    name: '480p',
  },
  {
    systemName: 'RES_720p',
    name: '720p',
  },
]

export const deviceConfiguration = {
  uploadMode: 'uploadMode',
  recordingSource: 'recordingSource',
  recordingResolution: 'recordingResolution',
  recordingMode: 'recordingMode',
}

export const tableCellTypes = {
  TEXT: 'TEXT',
  ACTION: 'ACTION',
  COMPONENT: 'COMPONENT',
}

export const eventTypes = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  DEVICE_PREVIEW: 'DEVICE_PREVIEW',
  DEVICE_NEW_CONFIGURATION: 'DEVICE_NEW_CONFIGURATION',
  START_UPLOADING_SEGMENT: 'START_UPLOADING_SEGMENT',
  SEGMENT_UPLOADED: 'SEGMENT_UPLOADED',
}
