import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import devicesApiService from '../../../api/devicesApiService/devicesApiService'
import { API_SUCCESS_MESSAGES } from '../../../api/apiSuccessMessages.constants'
import {
  didAccelerationChange,
  didConfigurationChange,
  didUploadTimeChange,
} from './devices.helpers'

export const getDevices = createAsyncThunk(
  'devices/getDevices',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { pagination, filters, searchLine } = getState().devices
      const params = {
        page: pagination.page,
        limit: pagination.size,
        currentStatus: filters.currentStatus !== 'all' ? filters.currentStatus : null,
        globalStatus: filters.globalStatus !== 'all' ? filters.globalStatus : null,
      }
      if (searchLine.trim() !== '') params.name = searchLine.trim()
      const response = await devicesApiService.getDevices(params)
      return {
        content: response.data.content,
        total: response.data?.totalElements,
        events: getState().devices.events,
      }
    } catch (e) {
      if (e?.code === 'ERR_CANCELED') return rejectWithValue(e.code)
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const getDeviceRegisterToken = createAsyncThunk(
  'devices/getDeviceRegisterToken',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await devicesApiService.getDeviceRegisterToken(data)
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const updateDeviceConfiguration = createAsyncThunk(
  'devices/updateDeviceConfiguration',
  async ({ id, setting, value }, { rejectWithValue }) => {
    try {
      const response = await devicesApiService.patchDeviceConfiguration(id, setting, value)
      return { response, id, setting, value }
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const editDevice = createAsyncThunk(
  'devices/editDevice',
  async (
    { deviceId, name, configuration, uploadTime, acceleration },
    { rejectWithValue, getState },
  ) => {
    try {
      const device = getState().devices.selectedDevice
      const initialConfiguration = device?.deviceConfiguration
      const initialUploadTime = initialConfiguration?.uploadTrigger?.uploadTime
      const initialAcceleration = initialConfiguration?.uploadTrigger?.acceleration
      const didNameChange = device?.name !== name
      const response = await Promise.all([
        didNameChange ? devicesApiService.editDeviceName(deviceId, name) : null,
        didConfigurationChange(initialConfiguration, configuration)
          ? devicesApiService.editDeviceConfiguration(deviceId, configuration)
          : null,
        didUploadTimeChange(initialUploadTime, uploadTime)
          ? devicesApiService.editDeviceUploadTime(deviceId, uploadTime)
          : null,
        didAccelerationChange(initialAcceleration, acceleration)
          ? devicesApiService.editDeviceAcceleration(deviceId, acceleration)
          : null,
        initialConfiguration.wifiOnly !== configuration.wifiOnly
          ? devicesApiService.patchWifiOnly(deviceId, configuration?.wifiOnly)
          : null,
      ])
      toast.success(API_SUCCESS_MESSAGES.EDIT_DEVICE)
      return { response, name: didNameChange ? name : null, deviceId }
    } catch (e) {
      if (!e.response) throw e
      toast.error(e.response.data.error)
      return rejectWithValue(e.response.data)
    }
  },
)

export const banDevice = createAsyncThunk(
  'devices/banDevice',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await devicesApiService.banDevice(id)
      return { response, id }
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const recoverDevice = createAsyncThunk(
  'devices/recoverDevice',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await devicesApiService.recoverDevice(id)
      return { response, id }
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response.data)
    }
  },
)

export const deleteDevice = createAsyncThunk(
  'devices/deleteDevice',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await devicesApiService.deleteDevice(id)
      toast.success(API_SUCCESS_MESSAGES.DELETE_DEVICE(`Camera n${id}`))
      return { response, id }
    } catch (e) {
      if (!e.response) throw e
      toast.error(e.response.data.error)
      return rejectWithValue(e.response.data)
    }
  },
)
