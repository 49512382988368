import React from 'react'
import { Stack, Box } from '@mui/material'
import UIModal from '../../../ui/UIModal/UIModal'
import UiTextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import UITableDoubleColor from '../../../ui/UITable/UITableDoubleColor'
import { getFormattedDate, getMinutesFromMilliseconds } from '../../../utils/helpers/date.helper'
import {
  getRecordingModeType,
  getRecordingSource,
  getResolutionType,
} from '../../../utils/helpers/enums.helper'

const SegmentInfoModal = ({ open, setOpen, data }) => {
  return data ? (
    <UIModal open={open} setOpen={setOpen}>
      <Stack gap="20px">
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <UiTextHeadlineLarge text="Segment info" />
          <UiTextHeadlineLarge
            text={`(Camera n${data?.deviceId})`}
            sx={{ opacity: 0.2, fontSize: '23px', fontWeight: 400 }}
          />
        </Box>
        <UITableDoubleColor
          data={[
            { title: 'ID', value: data?.id },
            { title: 'Start record time', value: getFormattedDate(data?.startRecording) },
            { title: 'Start uploading time', value: getFormattedDate(data?.startUploadingTime) },
            { title: 'Duration, min', value: getMinutesFromMilliseconds(data?.durationMillis) },
            { title: 'Segment status', value: data?.segmentStatus },
            { title: 'Storage', value: data?.bucket },
            { title: 'File URL', value: data?.fileUrl },
            { title: 'File name', value: data?.fileName },
            { title: 'Recording source', value: getRecordingSource(data?.recordingSource) },
            { title: 'Resolution', value: getResolutionType(data?.recordingResolution) },
            { title: 'Recording mode', value: getRecordingModeType(data?.recordingMode) },
          ]}
        />
      </Stack>
    </UIModal>
  ) : null
}

export default SegmentInfoModal
