import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import Logo from '../../assets/img/logo.svg'
import { COLOR } from '../../utils/constants/color.constants'

const UIFullScreenPreloader = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '30px',
      }}
    >
      <Box sx={{ width: '175px' }}>
        <img src={Logo} alt="IOTI logo" style={{ width: '100%' }} />
      </Box>
      <CircularProgress
        sx={{
          color: COLOR.BLACK,
        }}
      />
    </Box>
  )
}

export default UIFullScreenPreloader
