import React, { useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { CloudSync, CloudUpload, CloudDone } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import {
  addUploadingSegment,
  setIsSendingUploadingCommand,
} from '../../../../store/slices/segments/segments.reducer'
import { uploadSegments } from '../../../../store/slices/segments/segments.actions'
import { COLOR } from '../../../../utils/constants/color.constants'
import { currentSegmentStatusEnums } from '../../../../utils/constants/enums.constants'

const CurrentSegmentsActions = ({ data }) => {
  const device = useSelector((state) => state.devices.selectedDevice)
  const dispatch = useDispatch()

  const uploading = data?.initialData?.status === currentSegmentStatusEnums.UPLOADING
  const uploaded = data?.initialData?.status === currentSegmentStatusEnums.UPLOADED
  const uploadButtonDisabled =
    data?.initialData?.status === currentSegmentStatusEnums.UPLOADING ||
    data?.initialData?.status === currentSegmentStatusEnums.UPLOADED

  return (
    <Box component="span" sx={{ display: 'flex' }}>
      <IconButton
        sx={{
          width: '100%',
          height: '49.5px',
          borderRadius: 0,
        }}
        disabled={uploadButtonDisabled}
        onClick={() => {
          dispatch(
            uploadSegments({
              deviceId: device.id,
              data: { segmentNames: [data?.initialData?.segmentName] },
            }),
          )
          dispatch(
            setIsSendingUploadingCommand({
              segmentName: data?.initialData?.segmentName,
            }),
          )
        }}
      >
        {uploaded ? (
          <CloudDone sx={{ fill: COLOR.GREEN_SUCCESS }} />
        ) : uploading ? (
          <CloudSync sx={{ fill: COLOR.ORANGE }} />
        ) : data?.initialData?.isSendingCommand ? (
          <CloudUpload sx={{ fill: COLOR.GRAY_LIGHT }} />
        ) : data?.initialData?.status !== currentSegmentStatusEnums.RECORDING ? (
          <CloudUpload sx={{ fill: COLOR.GRAY_DARK }} />
        ) : null}
      </IconButton>
    </Box>
  )
}

export default CurrentSegmentsActions
