import React, { useEffect, useRef } from 'react'
import { Box, CircularProgress, CardMedia, Card } from '@mui/material'
import { useSelector } from 'react-redux'
import UITextTitleSmall from '../../../ui/UIText/UITextTitleSmall'
import { COLOR } from '../../../utils/constants/color.constants'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const RecorderPlayer = () => {
  const { loading, url, playing } = useSelector((state) => state.segments.video)
  const preview = useSelector((state) => state.devices.selectedDevice?.base64Preview)

  const videoRef = useRef()

  const handlePlayPause = () => {
    if (videoRef.current?.paused && playing) {
      try {
        videoRef.current?.play()
      } catch (e) {
        console.log(e)
      }
    } else if (!playing && !videoRef.current?.paused) {
      try {
        videoRef.current?.pause()
      } catch (e) {
        console.log(e)
      }
    }
  }

  useEffect(() => {
    if (!loading && url && playing && videoRef.current) {
      handlePlayPause()
    }
  }, [loading, url, playing])

  return (
    <Box
      sx={{
        width: '75%',
        height: '359px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLOR.GRAY_LIGHT,
        position: 'relative',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <VideoPlayer url={url} videoRef={videoRef} preview={preview} />
      )}
    </Box>
  )
}

export default RecorderPlayer
