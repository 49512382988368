import React from 'react'
import { Box, TablePagination } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setPage, setSize } from '../../../store/slices/devices/devices.reducer'
import RecorderListItem from './RecorderListItem'
import UIOverlayPreloader from '../../../ui/UIPreloader/UIOverlayPreloader'
import { COLOR } from '../../../utils/constants/color.constants'

const RecordersList = () => {
  const isLoading = useSelector((state) => state.devices.isLoading)
  const devices = useSelector((state) => state.devices.devices)
  const filtersCollapsed = useSelector((state) => state.devices.filters.collapsed)
  const pagination = useSelector((state) => state.devices.pagination)
  const dispatch = useDispatch()

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        className={['disable-scrollbar', filtersCollapsed ? 'expanded' : ''].join(' ')}
        sx={{
          height: 'calc(100vh - 130px)',
          pb: '52px',
          overflow: 'auto',
          position: 'relative',
          transition: '0.25s all ease-in-out',
          '&.expanded': {
            height: 'calc(100vh - 59px)',
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        {isLoading ? <UIOverlayPreloader /> : null}
        {devices.map((item) => {
          return <RecorderListItem key={item?.id} data={item} />
        })}
      </Box>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 50]}
        count={pagination.total}
        page={pagination.page}
        rowsPerPage={pagination.size}
        onPageChange={(e, newPage) => dispatch(setPage(newPage))}
        onRowsPerPageChange={(e) => dispatch(setSize(e.target.value))}
        showFirstButton
        showLastButton
        labelRowsPerPage="Cam per page:"
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: 0,
          left: 0,
          background: 'white',
          borderTop: '1px solid',
          borderColor: COLOR.GRAY_LIGHT,
        }}
      />
    </Box>
  )
}

export default RecordersList
