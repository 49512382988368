import React, { useState, useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { SecurityUpdateWarningRounded } from '@mui/icons-material'
import UITextBodyMedium from '../../../ui/UIText/UITextBodyMedium'
import { COLOR } from '../../../utils/constants/color.constants'

const RecorderCountdown = ({ handleComplete, duration }) => {
  const [remainingTime, setRemainingTime] = useState(parseFloat(duration))

  const remainingHours = Math.floor(remainingTime / 3600)
  const remainingHoursString =
    remainingHours.toString().length === 1 ? `0${remainingHours}` : remainingHours
  const remainingMinutes = Math.floor((remainingTime - remainingHours * 3600) / 60)
  const remainingMinutesString =
    remainingMinutes.toString().length === 1 ? `0${remainingMinutes}` : remainingMinutes
  const remainingSeconds = Math.floor(remainingTime - remainingHours * 3600 - remainingMinutes * 60)
  const remainingSecondsString =
    remainingSeconds.toString().length === 1 ? `0${remainingSeconds}` : remainingSeconds

  useEffect(() => {
    setRemainingTime(duration)
    let timer = setInterval(() => {
      setRemainingTime((prev) => {
        const newRemainingTime = prev - 1
        if (newRemainingTime <= 0) {
          clearInterval(timer)
          timer = null
          handleComplete()
          return 0
        }
        return newRemainingTime
      })
    }, 1000)
    return () => {
      clearInterval(timer)
      timer = null
    }
  }, [duration])

  return remainingTime > 0 ? (
    <Box sx={{ p: '3px' }}>
      <Stack direction="row" gap="4px" alignItems="center">
        <SecurityUpdateWarningRounded
          sx={{
            fill: COLOR.ORANGE,
          }}
        />
        <UITextBodyMedium
          text={`${remainingHoursString}:${remainingMinutesString}:${remainingSecondsString}`}
          sx={{ lineHeight: 'normal', marginBlockStart: 0, marginBlockEnd: 0 }}
        />
      </Stack>
    </Box>
  ) : null
}

export default RecorderCountdown
