import { VpnKeyRounded } from '@mui/icons-material'
import { tableCellTypes } from '../constants/enums.constants'
import { getFormattedDate, getFormattedDuration, getMinutesFromMilliseconds } from './date.helper'
import { getResolutionType } from './enums.helper'
import { COLOR } from '../constants/color.constants'
import { formatApiKey } from './common.helpers'

export const getSavedSegmentsTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        { type: tableCellTypes.TEXT, value: 'Unknown' },
        { type: tableCellTypes.TEXT, value: getFormattedDate(item?.startRecording) },
        { type: tableCellTypes.TEXT, value: getFormattedDuration(item?.durationMillis) },
        { type: tableCellTypes.TEXT, value: item?.bucket },
        { type: tableCellTypes.ACTION },
      ],
    }
  })
}

export const getCurrentSegmentsTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        { type: tableCellTypes.TEXT, value: item?.segmentName },
        { type: tableCellTypes.TEXT, value: getFormattedDate(item?.startRecording) },
        { type: tableCellTypes.TEXT, value: getFormattedDuration(item?.durationMillis) },
        { type: tableCellTypes.TEXT, value: item?.status },
        { type: tableCellTypes.TEXT, value: getResolutionType(item?.recordingResolution) },
        { type: tableCellTypes.ACTION },
      ],
    }
  })
}

export const getCustomersTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        { type: tableCellTypes.TEXT, value: `#${item?.id}` },
        { type: tableCellTypes.TEXT, value: item?.name },
        { type: tableCellTypes.TEXT, value: item?.amountOfAdmins },
        { type: tableCellTypes.TEXT, value: item?.amountOfOperators },
        { type: tableCellTypes.TEXT, value: item?.amountOfDevices },
        { type: tableCellTypes.ACTION },
      ],
    }
  })
}

export const getAdminsTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        { type: tableCellTypes.TEXT, value: `#${item?.userId}` },
        { type: tableCellTypes.TEXT, value: item?.name },
        { type: tableCellTypes.TEXT, value: item?.surname },
        { type: tableCellTypes.TEXT, value: item?.mail },
        { type: tableCellTypes.ACTION },
      ],
    }
  })
}

export const getTriggersTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        { type: tableCellTypes.TEXT, value: `#${item?.id}` },
        { type: tableCellTypes.TEXT, value: item?.device?.name },
        { type: tableCellTypes.TEXT, value: getFormattedDate(item?.triggerTime) },
        { type: tableCellTypes.TEXT, value: 'Acceleration' },
        { type: tableCellTypes.ACTION },
      ],
    }
  })
}

export const getDeviceTriggersTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        { type: tableCellTypes.TEXT, value: `#${item?.id}` },
        { type: tableCellTypes.TEXT, value: getFormattedDate(item?.triggerTime) },
        { type: tableCellTypes.TEXT, value: 'Acceleration' },
        { type: tableCellTypes.ACTION },
      ],
    }
  })
}

export const getSegmentGroupSegmentsTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        { type: tableCellTypes.TEXT, value: getFormattedDate(item?.startRecording) },
        { type: tableCellTypes.TEXT, value: getFormattedDuration(item?.durationMillis) },
        { type: tableCellTypes.ACTION },
      ],
    }
  })
}

export const getSegmentGroupSegmentsExtendedTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        { type: tableCellTypes.TEXT, value: getFormattedDate(item?.startRecording) },
        { type: tableCellTypes.TEXT, value: getFormattedDuration(item?.durationMillis) },
        { type: tableCellTypes.TEXT, value: item?.bucket ?? '' },
        { type: tableCellTypes.ACTION },
      ],
    }
  })
}

export const getApiKeysTableData = (data) => {
  return data.map((item) => {
    return {
      initialData: item,
      cells: [
        {
          type: tableCellTypes.COMPONENT,
          value: <VpnKeyRounded sx={{ fill: COLOR.GRAY_LIGHT }} />,
          sx: {
            width: '40px',
          },
        },
        {
          type: tableCellTypes.TEXT,
          value: item?.label ?? 'No title',
        },
        {
          type: tableCellTypes.TEXT,
          value: item?.apiKey ? formatApiKey(item?.apiKey) : '-',
        },
        {
          type: tableCellTypes.TEXT,
          value: getFormattedDate(item?.createTime) ?? '-',
        },
        {
          type: tableCellTypes.ACTION,
        },
      ],
    }
  })
}
