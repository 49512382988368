import { addCustomer } from '../customers.actions'

export default (builder) => {
  builder
    .addCase(addCustomer.pending, (state) => {
      state.isLoadingAddCustomer = true
    })
    .addCase(addCustomer.fulfilled, (state) => {
      state.isLoadingAddCustomer = false
      state.errorAddCustomer = null
    })
    .addCase(addCustomer.rejected, (state, action) => {
      state.errorAddCustomer = action.payload
      state.isLoadingAddCustomer = false
    })
}
