import React, { useEffect, useState } from 'react'
import { Box, Stack, TablePagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import UIPaper from '../../ui/UIPaper/UIPaper'
import UITableControls from '../../ui/UITable/UITableControls'
import UITable from '../../ui/UITable/UITable'
import { apiKeysTableHead } from '../../utils/constants/tables.constants'
import { getApiKeys } from '../../store/slices/apiKeys/apiKeys.actions'
import { getApiKeysTableData } from '../../utils/helpers/tables.helper'
import ApiKeysActionItems from './ApiKeysActionItems'
import UIOverlayPreloader from '../../ui/UIPreloader/UIOverlayPreloader'
import AddApiKeyModal from '../../components/Modals/AddApiKeyModal/AddApiKeyModal'
import { setApiKeysQueryParams } from '../../store/slices/apiKeys/apiKeys.reducer'
import { setTriggersPage, setTriggersSize } from '../../store/slices/triggers/triggers.reducer'

const APIKeys = () => {
  const isLoading = useSelector((state) => state.apiKeys.isLoading)
  const apiKeys = useSelector((state) => state.apiKeys.apiKeys.content)
  const total = useSelector((state) => state.apiKeys.apiKeys.totalElements)
  const params = useSelector((state) => state.apiKeys.apiKeys.queryParams)
  const dispatch = useDispatch()

  const [addApiKeyModal, setAddApiKeyModal] = useState(false)

  useEffect(() => {
    dispatch(getApiKeys())
  }, [params])

  return (
    <Box
      sx={{
        p: '30px',
      }}
    >
      <UIPaper>
        <Stack gap="30px">
          <UITableControls
            addText="Add a new API key"
            title="API keys"
            onAdd={() => setAddApiKeyModal(true)}
            onRefresh={() => dispatch(getApiKeys())}
          />
          <Box sx={{ position: 'relative' }}>
            {isLoading && <UIOverlayPreloader />}
            <UITable
              head={apiKeysTableHead}
              data={getApiKeysTableData(apiKeys)}
              sort={params.sort}
              setSort={(value) =>
                dispatch(setApiKeysQueryParams({ ...params, page: 0, sort: value }))
              }
              emptyText="No API keys found."
              renderActionItems={(data) => <ApiKeysActionItems data={data} />}
            />
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={total}
              page={params.page}
              rowsPerPage={params.size}
              onPageChange={(e, newPage) =>
                dispatch(setApiKeysQueryParams({ ...params, page: newPage }))
              }
              onRowsPerPageChange={(e) =>
                dispatch(setApiKeysQueryParams({ ...params, page: 0, size: e.target.value }))
              }
              showFirstButton
              showLastButton
              labelRowsPerPage="Items per page:"
            />
          </Box>
        </Stack>
      </UIPaper>
      {addApiKeyModal && <AddApiKeyModal open={addApiKeyModal} setOpen={setAddApiKeyModal} />}
    </Box>
  )
}

export default APIKeys
