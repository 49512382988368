import React from 'react'
import { IconButton } from '@mui/material'
import { FilterAltRounded, FilterAltOffRounded } from '@mui/icons-material'
import { COLOR } from '../../../utils/constants/color.constants'

const UIIconButtonFilter = ({ open, sx, ...props }) => {
  return (
    <IconButton
      sx={{
        height: '34.5px',
        width: '34.5px',
        borderRadius: '6px',
        transition: '0.25s all ease-in-out',
        background: COLOR.GRAY_MEDIUM,
        '&:hover': {
          transition: '0.25s all ease-in-out',
          background: COLOR.ORANGE,
        },
        ...sx,
      }}
      {...props}
    >
      {open ? (
        <FilterAltOffRounded sx={{ fill: 'white' }} />
      ) : (
        <FilterAltRounded sx={{ fill: 'white' }} />
      )}
    </IconButton>
  )
}

export default UIIconButtonFilter
