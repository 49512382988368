import { updateDeviceConfiguration } from '../devices.actions'

export default (builder) => {
  builder
    .addCase(updateDeviceConfiguration.pending, (state, action) => {
      state.patchDeviceConfiguration.isLoading = true
    })
    .addCase(updateDeviceConfiguration.fulfilled, (state, action) => {
      state.patchDeviceConfiguration.isLoading = false
      state.patchDeviceConfiguration.error = null
      const updateDevice = state.devices.find((item) => item.id === action.payload.id)
      if (updateDevice) {
        updateDevice.deviceConfiguration[action.payload.setting] = action.payload.value
        state.selectedDevice.deviceConfiguration[action.payload.setting] = action.payload.value
      }
    })
    .addCase(updateDeviceConfiguration.rejected, (state, action) => {
      state.patchDeviceConfiguration.isLoading = false
      state.patchDeviceConfiguration.error = action.payload
    })
}
