import React from 'react'
import { Box, Stack } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { adminSidebarMenu, systemAdminMenu } from '../../../utils/constants/menu.constants'
import UIButtonNav from '../../../ui/UIButton/UIButtonNav'
import { generateReactKey } from '../../../utils/helpers/reactKey.helper'
import { ROLES } from '../../../utils/constants/global.constants'

const SidebarMenu = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const role = useSelector((state) => state.auth.user?.role)

  const menu =
    role === ROLES.ADMIN ? adminSidebarMenu : role === ROLES.SYSTEM_ADMIN ? systemAdminMenu : []

  return (
    <Box
      sx={{
        px: '25.88px',
        pt: '10px',
      }}
    >
      <Stack gap="17px">
        {menu.map((item) => {
          return (
            <UIButtonNav
              key={generateReactKey()}
              text={item.name}
              className={pathname.includes(item.link) ? 'active' : ''}
              onClick={() => {
                navigate(item.link)
              }}
            />
          )
        })}
      </Stack>
    </Box>
  )
}

export default SidebarMenu
