import React from 'react'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { COLOR } from '../../../utils/constants/color.constants'

const UIIconButtonClose = ({ sx, ...props }) => {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        top: '23px',
        right: '20px',
        ...sx,
      }}
      {...props}
    >
      <Close sx={{ fill: COLOR.GRAY_DARK }} />
    </IconButton>
  )
}

export default UIIconButtonClose
