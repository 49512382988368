export const customersTableHead = [
  {
    name: 'ID',
  },
  {
    name: 'Name',
  },
  {
    name: 'Admins',
  },
  {
    name: 'Operators',
  },
  {
    name: 'Devices',
  },
  {
    name: 'Actions',
    type: 'action',
  },
]

export const adminsTableHead = [
  {
    name: 'ID',
  },
  {
    name: 'Name',
  },
  {
    name: 'Surname',
  },
  {
    name: 'Email',
  },
  {
    name: 'Actions',
    type: 'action',
  },
]

export const devicesTableHead = [
  {
    name: 'ID',
  },
  {},
]

export const currentSegmentsTableHead = [
  {
    name: 'Name',
  },
  {
    name: 'Start recording',
  },
  {
    name: 'Duration',
  },
  {
    name: 'Status',
  },
  {
    name: 'Resolution',
  },
  {
    name: 'Actions',
    type: 'action',
  },
]

export const triggersTableHead = [
  { name: '#', sortable: true, sortAsc: 'id,asc', sortDesc: 'id,desc' },
  { name: 'Recorder', sortable: true, sortAsc: 'device.name,asc', sortDesc: 'device.name,desc' },
  {
    name: 'Triggered at',
    sortable: true,
    sortAsc: 'triggerTime,asc',
    sortDesc: 'triggerTime,desc',
  },
  { name: 'Trigger type' },
  { name: 'Segments', type: 'action' },
]

export const deviceTriggersTableHead = [
  { name: '#', sortable: true, sortAsc: 'id,asc', sortDesc: 'id,desc' },
  {
    name: 'Triggered at',
    sortable: true,
    sortAsc: 'triggerTime,asc',
    sortDesc: 'triggerTime,desc',
  },
  { name: 'Trigger type' },
  { name: 'Segments', type: 'action' },
]

export const segmentGroupSegmentsTableHead = [
  { name: 'Start recording time' },
  { name: 'Duration' },
  { name: 'Actions', type: 'actions' },
]

export const segmentGroupSegmentsExtendedTableHead = [
  { name: 'Start recording time' },
  { name: 'Duration' },
  { name: 'Storage' },
  { name: 'Actions', type: 'actions' },
]

export const apiKeysTableHead = [
  {
    name: '',
  },
  {
    name: 'Name',
  },
  {
    name: 'Key',
  },
  {
    name: 'Created at',
    sortable: true,
    sortAsc: 'createTime,asc',
    sortDesc: 'createTime,desc',
  },
  {
    name: '',
    type: 'actions',
  },
]
