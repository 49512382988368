import React from 'react'
import { Stack } from '@mui/material'
import UIModal from '../../../ui/UIModal/UIModal'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import EditApiKeyForm from '../../Forms/FormTemplates/EditApiKeyForm/EditApiKeyForm'

const EditApiKeyModal = ({ open, setOpen, data }) => {
  return (
    <UIModal wrapperStyles={{ width: '500px' }} open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text="Edit API key" />
        <EditApiKeyForm data={data} onClose={() => setOpen(false)} />
      </Stack>
    </UIModal>
  )
}

export default EditApiKeyModal
