import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedDevice } from '../../../store/slices/devices/devices.reducer'
import { resetSegmentsData } from '../../../store/slices/segments/segments.reducer'
import UiTextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import { COLOR } from '../../../utils/constants/color.constants'
import UiTextHeadlineSmall from '../../../ui/UIText/UITextHeadlineSmall'
import RecorderSettings from './RecorderSettings'
import RecorderStatusManagement from './RecorderStatusManagement'
import RecorderSegments from '../Segments/RecorderSegments'
import UIIconButtonClose from '../../../ui/UIButton/UIIconButton/UIIconButtonClose'
import SegmentsControls from '../Segments/SegmentsControls'
import RecorderPlayer from './RecorderPlayer'

const RecorderSelected = () => {
  const device = useSelector((state) => state.devices.selectedDevice)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetSegmentsData())
  }, [device?.id])

  return (
    <Box
      sx={{
        flex: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      {device ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: '20px',
              pb: '10px',
              position: 'relative',
            }}
          >
            <UiTextHeadlineLarge
              text={device?.name ?? `Camera n${device?.id}`}
              sx={{ mb: '12px' }}
            />
            <RecorderPlayer />
            <RecorderSettings />
            <UIIconButtonClose onClick={() => dispatch(setSelectedDevice(null))} />
            {/* <RecorderStatusManagement /> */}
            <SegmentsControls />
          </Box>
          <RecorderSegments />
        </>
      ) : (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <UiTextHeadlineSmall text="Select a camera..." sx={{ color: COLOR.GRAY_LIGHT }} />
        </Box>
      )}
    </Box>
  )
}

export default RecorderSelected
