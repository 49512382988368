import React from 'react'
import { Form, Formik } from 'formik'
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { updateAdmin } from '../../../../store/slices/customers/customers.reducer'
import { editAdmin } from '../../../../store/slices/customers/customers.actions'
import FormInput from '../../FormComponents/FormInput/FormInput'
import UITextBodyMedium from '../../../../ui/UIText/UITextBodyMedium'
import { COLOR } from '../../../../utils/constants/color.constants'
import UIButtonMedium from '../../../../ui/UIButton/UIButtonMedium'
import UIButtonMediumDanger from '../../../../ui/UIButton/UIButtomMediumDanger'
import { editAdminFormValidationSchema } from './editAdminForm.constants'

const EditAdminForm = ({ onClose, initialData, userId }) => {
  const isLoading = useSelector((state) => state.customers.editAdmin.isLoading)
  const error = useSelector((state) => state.customers.editAdmin.error)
  const dispatch = useDispatch()

  const handleSubmit = (values, { resetForm }) => {
    dispatch(editAdmin({ userId, data: values }))
      .then(() => {
        dispatch(
          updateAdmin({
            userId,
            ...values,
          }),
        )
        resetForm()
        onClose()
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  return (
    <Formik
      initialValues={initialData}
      onSubmit={handleSubmit}
      validationSchema={editAdminFormValidationSchema}
    >
      {(props) => (
        <Form>
          <Stack gap="30px">
            <FormInput
              type="text"
              id="firstName"
              label="First name"
              name="name"
              disabled={isLoading}
              required
            />
            <FormInput
              type="text"
              id="lastName"
              label="Last name"
              name="surname"
              disabled={isLoading}
              required
            />
            <FormInput
              type="text"
              id="email"
              label="Email"
              name="mail"
              disabled={isLoading}
              required
            />
            {error && error !== '' ? (
              <UITextBodyMedium text={error} sx={{ color: COLOR.RED_ERROR }} />
            ) : null}
            <Stack direction="row" gap="10px" sx={{ mt: '10px' }}>
              <UIButtonMediumDanger onClick={onClose} fullWidth>
                Cancel
              </UIButtonMediumDanger>
              <UIButtonMedium
                fullWidth
                type="submit"
                disabled={isLoading || (props.touched && !props.isValid)}
              >
                Update
              </UIButtonMedium>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default EditAdminForm
