import React from 'react'
import { IconButton } from '@mui/material'
import { COLOR } from '../../../utils/constants/color.constants'

const UIIconButtonMedium = ({
  children,
  sx,
  accentColor = COLOR.ORANGE,
  size = 'SMALL',
  ...props
}) => {
  return (
    <IconButton
      sx={{
        '& svg': {
          fill: COLOR.GRAY_DARK,
          transition: '0.25s all ease-in-out',
        },
        '&:hover': {
          '& svg': {
            fill: accentColor,
            transition: '0.25s all ease-in-out',
          },
        },
        '&.active': {
          '& svg': {
            fill: accentColor,
            transition: '0.25s all ease-in-out',
          },
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </IconButton>
  )
}

export default UIIconButtonMedium
