import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from './slices/auth/auth.reducer'
import DevicesSlice from './slices/devices/devices.reducer'
import SegmentsSlice from './slices/segments/segments.reducer'
import CustomersSlice from './slices/customers/customers.reducer'
import TriggersReducer from './slices/triggers/triggers.reducer'
import apiKeysReducer from './slices/apiKeys/apiKeys.reducer'

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    devices: DevicesSlice,
    segments: SegmentsSlice,
    customers: CustomersSlice,
    triggers: TriggersReducer,
    apiKeys: apiKeysReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export default store
