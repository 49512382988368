export const getToken = () => {
  return localStorage.getItem('token')
}

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken')
}

export const setToken = (token) => {
  return localStorage.setItem('token', token)
}

export const setRefreshToken = (token) => {
  return localStorage.setItem('refreshToken', token)
}

export const removeToken = () => {
  localStorage.removeItem('token')
}

export const removeRefreshToken = () => {
  localStorage.removeItem('refreshToken')
}
