import React from 'react'
import { Typography } from '@mui/material'

const UiTextHeadlineLarge = ({ text, sx, children, ...props }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: 0,
        ...sx,
      }}
      {...props}
    >
      {children || text}
    </Typography>
  )
}

export default UiTextHeadlineLarge
