import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo, signIn } from '../../../../store/slices/auth/auth.actions'
import FormInput from '../../FormComponents/FormInput/FormInput'
import UIButtonMedium from '../../../../ui/UIButton/UIButtonMedium'
import UITextHeadlineLarge from '../../../../ui/UIText/UITextHeadlineLarge'
import { COLOR } from '../../../../utils/constants/color.constants'
import UITextBodyMedium from '../../../../ui/UIText/UITextBodyMedium'
import { demoCredentials } from '../../../../utils/constants/demo.constants'
import SignInHints from './SignInHints'

const SignInForm = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.auth.isLoading)
  const error = useSelector((state) => state.auth.error)

  const handleSubmit = (values) => {
    dispatch(signIn(values))
      .unwrap()
      .then(() => {
        dispatch(getUserInfo())
      })
  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <Stack gap="30px">
            {/* <SignInHints values={props.values} setValues={props.setValues} /> */}
            <UITextHeadlineLarge text="Sign in" sx={{ textAlign: 'center' }} />
            <Stack gap="20px">
              <FormInput type="text" id="username" label="User name" name="username" />
              <FormInput type="password" id="password" label="Password" name="password" />
              {error !== '' ? (
                <UITextBodyMedium text={error} sx={{ color: COLOR.RED_ERROR }} />
              ) : null}
              <UIButtonMedium type="submit" disabled={isLoading}>
                Confirm
              </UIButtonMedium>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default SignInForm
