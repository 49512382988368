import $api from '../api'

class TriggersApiService {
  #api

  #endPoints

  constructor({ api }) {
    this.#api = api
    this.#endPoints = {
      triggers: '/triggers',
      triggerById: (id) => `/triggers/${id}`,
    }
  }

  getTriggers = async (params) => {
    const response = await this.#api.get(this.#endPoints.triggers, {
      params,
    })
    return response
  }
}

const triggersApiService = new TriggersApiService({ api: $api })

export default triggersApiService
