import React from 'react'
import { Box, IconButton } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { COLOR } from '../../../utils/constants/color.constants'
import UITextBodyMedium from '../../../ui/UIText/UITextBodyMedium'
import UITable from '../../../ui/UITable/UITable'
import { segmentGroupSegmentsTableHead } from '../../../utils/constants/tables.constants'
import { getSegmentGroupSegmentsTableData } from '../../../utils/helpers/tables.helper'
import SegmentsSideTableActions from './SegmentActions/SegmentsSideTableActions'

const SegmentsSideTable = ({ open, setOpen, setSegmentInfoModal, setSegmentInfoModalData }) => {
  const selectedSegmentGroup = useSelector((state) => state.triggers.selectedSegmentGroup.data)
  const isLoading = useSelector((state) => state.triggers.selectedSegmentGroup.isLoading)

  return (
    <Box
      className={open ? 'open' : ''}
      sx={{
        height: 'calc(100vh - 489px)',
        width: '100%',
        position: 'absolute',
        top: '48px',
        right: '0',
        background: 'white',
        zIndex: 2,
        transition: '0.3s all ease-in-out',
        opacity: 0,
        pointerEvents: 'none',
        overflowY: 'auto',
        '&.open': {
          opacity: 1,
          pointerEvents: 'auto',
          transition: '0.3s all ease-in-out',
        },
      }}
    >
      <Box
        sx={{
          height: '48px',
          borderBottom: '1px solid',
          borderColor: COLOR.GRAY_LIGHT,
          pl: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <IconButton onClick={() => setOpen(false)}>
          <ArrowBack />
        </IconButton>
        <UITextBodyMedium text={selectedSegmentGroup?.name} />
      </Box>
      {selectedSegmentGroup ? (
        <UITable
          head={segmentGroupSegmentsTableHead}
          data={getSegmentGroupSegmentsTableData(selectedSegmentGroup?.segments ?? [])}
          emptyText="No segments found."
          renderActionItems={(data) => (
            <SegmentsSideTableActions
              data={data}
              setSegmentInfoModal={setSegmentInfoModal}
              setSegmentInfoModalData={setSegmentInfoModalData}
            />
          )}
        />
      ) : null}
    </Box>
  )
}

export default SegmentsSideTable
