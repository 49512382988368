import React from 'react'
import { Stack } from '@mui/material'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import UIModal from '../../../ui/UIModal/UIModal'
import EditAdminForm from '../../Forms/FormTemplates/EditAdminForm/EditAdminForm'

const EditAdminModal = ({ open, setOpen, data }) => {
  return (
    <UIModal wrapperStyles={{ width: '500px' }} open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text={`Edit admin "${data?.name}"`} />
        <EditAdminForm
          onClose={() => setOpen(false)}
          initialData={{
            name: data?.name,
            surname: data?.surname,
            mail: data?.mail,
          }}
          userId={data?.userId}
        />
      </Stack>
    </UIModal>
  )
}

export default EditAdminModal
