import { updateApiKey } from '../apiKeys.actions'

export default (builder) => {
  builder
    .addCase(updateApiKey.pending, (state) => {
      state.update.isLoading = true
    })
    .addCase(updateApiKey.fulfilled, (state, action) => {
      state.update.isLoading = false
      state.update.error = null
    })
    .addCase(updateApiKey.rejected, (state, action) => {
      state.update.isLoading = false
      state.update.error = action.payload
    })
}
