import { createAsyncThunk } from '@reduxjs/toolkit'
import customerApiService from '../../../api/customersApiService/customerApiService'
import { apiErrors } from '../../../api/apiErrors.constants'

export const getCustomers = createAsyncThunk(
  'customers/getCustomers',
  async (data, { rejectWithValue }) => {
    try {
      const response = await customerApiService.getCustomers()
      return response.data.content
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response?.message)
    }
  },
)

export const getCustomer = createAsyncThunk(
  'customers/getCustomer',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await customerApiService.getCustomer(id)
      return response.data.content
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e.response?.message)
    }
  },
)

export const addCustomer = createAsyncThunk(
  'customers/addCustomer',
  async ({ name }, { rejectWithValue }) => {
    try {
      const response = await customerApiService.addCustomer({ name })
      return response
    } catch (e) {
      if (!e.response) throw e
      if (e?.response?.status === 409) return rejectWithValue(apiErrors.customerError409)
      return rejectWithValue(e?.response?.data?.message)
    }
  },
)

export const addAdmin = createAsyncThunk(
  'customers/addAdmin',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await customerApiService.addAdminToCustomer({ id, data })
      return response.data
    } catch (e) {
      if (!e.response) throw e
      if (e?.response?.status === 409) return rejectWithValue(apiErrors.userName)
      return rejectWithValue(e?.response?.data?.message)
    }
  },
)

export const editAdmin = createAsyncThunk(
  'customers/editAdmin',
  async ({ userId, data }, { rejectWithValue }) => {
    try {
      const response = await customerApiService.editAdmin({ userId, data })
      return response.data
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e?.response?.data?.message)
    }
  },
)

export const deleteCustomer = createAsyncThunk(
  'customers/deleteCustomer',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await customerApiService.deleteCustomer(id)
      return { data: response.data, id }
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e?.response?.data?.message)
    }
  },
)

export const recoverCustomer = createAsyncThunk(
  'customers/recoverCustomer',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await customerApiService.recoverCustomer(id)
      return { data: response.data, id }
    } catch (e) {
      if (!e.response) throw e
      return rejectWithValue(e?.response?.data?.message)
    }
  },
)
