import React from 'react'
import { Tab, Tabs } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'

const UITabs = ({ value, onChange, children, sx, ...props }) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      sx={{
        width: '100%',
        '& .MuiTabs-indicator': {
          background: COLOR.ORANGE,
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Tabs>
  )
}

export default UITabs
