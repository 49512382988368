import { createSlice } from '@reduxjs/toolkit'
import deleteApiKeyBuilder from './builders/deleteApiKey.builder'
import updateApiKeyBuilder from './builders/updateApiKey.builder'
import getApiKeysBuilder from './builders/getApiKeys.builder'
import createApiKeyBuilder from './builders/createApiKey.builder'

const initialState = {
  isLoading: false,
  error: null,
  apiKeys: {
    content: [],
    totalElements: 0,
    queryParams: {
      page: 0,
      size: 10,
      sort: '',
    },
  },
  create: {
    isLoading: false,
    error: null,
  },
  update: {
    isLoading: false,
    error: null,
  },
  delete: {
    isLoading: false,
    error: null,
  },
}

const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    setApiKeysQueryParams: (state, action) => {
      state.apiKeys.queryParams = action.payload
    },
  },
  extraReducers: (builder) => {
    updateApiKeyBuilder(builder)
    deleteApiKeyBuilder(builder)
    getApiKeysBuilder(builder)
    createApiKeyBuilder(builder)
  },
})

export const { setApiKeysQueryParams } = apiKeysSlice.actions

export default apiKeysSlice.reducer
