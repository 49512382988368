import { getTriggers } from '../triggers.actions'

export default (builder) => {
  builder
    .addCase(getTriggers.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getTriggers.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = null
      state.triggers = action.payload?.data?.content
      state.pagination.total = action.payload?.data?.totalElements
      if (action.payload?.deviceId) state.filters.deviceId = action.payload?.deviceId
    })
    .addCase(getTriggers.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
