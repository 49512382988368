import React from 'react'
import { Box } from '@mui/material'
import Logo from '../../../assets/img/logo.svg'

const SidebarHeader = () => {
  return (
    <Box
      sx={{
        py: '16px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '175px' }}>
        <img src={Logo} alt="IOTI logo" style={{ width: '100%' }} />
      </Box>
    </Box>
  )
}

export default SidebarHeader
