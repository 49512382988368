import { toast } from 'react-toastify'
import { deleteDevice } from '../devices.actions'

export default (builder) => {
  builder
    .addCase(deleteDevice.pending, (state) => {
      state.deleteDevice.isLoading = true
    })
    .addCase(deleteDevice.fulfilled, (state) => {
      state.deleteDevice.isLoading = false
      state.deleteDevice.error = null
      state.selectedDevice = null
    })
    .addCase(deleteDevice.rejected, (state, action) => {
      state.deleteDevice.isLoading = false
      state.deleteDevice.error = action.payload
    })
}
