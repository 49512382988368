import React, { useEffect } from 'react'
import { Box, Stack, TablePagination, IconButton } from '@mui/material'
import { ViewListRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getTriggers } from '../../store/slices/triggers/triggers.actions'
import {
  setTriggersSort,
  setTriggersSize,
  setTriggersPage,
  resetTriggersData,
} from '../../store/slices/triggers/triggers.reducer'
import UITextHeadlineLarge from '../../ui/UIText/UITextHeadlineLarge'
import UIPaper from '../../ui/UIPaper/UIPaper'
import UITable from '../../ui/UITable/UITable'
import UIOverlayPreloader from '../../ui/UIPreloader/UIOverlayPreloader'
import { getTriggersTableData } from '../../utils/helpers/tables.helper'
import { triggersTableHead } from '../../utils/constants/tables.constants'
import { COLOR } from '../../utils/constants/color.constants'
import UITextBodyMedium from '../../ui/UIText/UITextBodyMedium'

const TriggersHistory = () => {
  const isLoading = useSelector((state) => state.triggers.isLoading)
  const triggers = useSelector((state) => state.triggers.triggers)
  const pagination = useSelector((state) => state.triggers.pagination)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const renderActionItems = (data) => {
    return (
      <Stack direction="row" gap="5px" sx={{ pr: '20px' }} alignItems="center">
        <IconButton onClick={() => navigate(`/history/segment-groups/${data?.initialData?.id}`)}>
          <ViewListRounded
            sx={{
              fill: COLOR.GRAY_MEDIUM,
            }}
          />
        </IconButton>
        <UITextBodyMedium
          sx={{ color: COLOR.GRAY_MEDIUM }}
          text={`(${data?.initialData?.segmentGroup?.amountOfSegments})`}
        />
      </Stack>
    )
  }

  useEffect(() => {
    dispatch(getTriggers({}))
  }, [pagination.page, pagination.size, pagination.sort])

  useEffect(() => {
    return () => {
      dispatch(resetTriggersData())
    }
  }, [])

  return (
    <Box
      sx={{
        flex: 2,
        p: '30px',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Stack gap="30px">
        <UITextHeadlineLarge text="Triggers history" />
        <UIPaper>
          <Box
            sx={{
              borderRadius: '6px',
              overflow: 'hidden',
              position: 'relative',
              maxHeight: '70vh',
              overflowY: 'auto',
            }}
          >
            {isLoading ? <UIOverlayPreloader /> : null}
            <UITable
              head={triggersTableHead}
              data={getTriggersTableData(triggers)}
              emptyText="History is empty"
              variant="rounded"
              renderActionItems={renderActionItems}
              sort={pagination.sort}
              setSort={(value) => dispatch(setTriggersSort(value))}
            />
          </Box>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 50]}
            count={pagination.total}
            page={pagination.page}
            rowsPerPage={pagination.size}
            onPageChange={(e, newPage) => dispatch(setTriggersPage(newPage))}
            onRowsPerPageChange={(e) => dispatch(setTriggersSize(e.target.value))}
            showFirstButton
            showLastButton
            labelRowsPerPage="Items per page:"
          />
        </UIPaper>
      </Stack>
    </Box>
  )
}

export default TriggersHistory
