import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import RecordersMain from './RecordersMain/RecordersMain'
import RecorderSelected from './RecorderSelected/RecorderSelected'

const Recorders = () => {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <RecordersMain />
      <RecorderSelected />
    </Box>
  )
}

export default Recorders
