import React from 'react'
import { Button } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'

const UIButtonLinkSmall = ({ sx, children, ...props }) => {
  return (
    <Button
      disableRipple
      sx={{
        p: 0,
        position: 'relative',
        textTransform: 'none',
        fontSize: '14px',
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 700,
        color: COLOR.GRAY_MEDIUM,
        transition: '0.25s all ease-in-out',
        '&:hover': {
          color: COLOR.ORANGE,
          background: 'none',
          transition: '0.25s all ease-in-out',
        },
        '&:focus': {
          background: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default UIButtonLinkSmall
