import React from 'react'
import { Stack } from '@mui/material'
import UIModal from '../../../ui/UIModal/UIModal'
import UITextHeadlineLarge from '../../../ui/UIText/UITextHeadlineLarge'
import AddCustomerForm from '../../Forms/FormTemplates/AddCustomerForm/AddCustomerForm'

const AddCustomerModal = ({ open, setOpen }) => {
  return (
    <UIModal open={open} setOpen={setOpen}>
      <Stack gap="30px">
        <UITextHeadlineLarge text="Add new customer" />
        <AddCustomerForm onClose={() => setOpen(false)} />
      </Stack>
    </UIModal>
  )
}

export default AddCustomerModal
