import React, { useState } from 'react'
import { Box } from '@mui/material'
import UIButtonNav from '../UIButton/UIButtonNav'
import { generateReactKey } from '../../utils/helpers/reactKey.helper'
import { COLOR } from '../../utils/constants/color.constants'

const UIDropdown = ({ list }) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(list[0])

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <UIButtonNav
        className="active"
        text={value}
        onClick={() => setOpen(!open)}
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
      />
      <Box
        className={open ? 'active' : ''}
        sx={{
          position: 'absolute',
          bottom: 'calc(100% - 5px)',
          pb: '5px',
          left: 0,
          opacity: 0,
          transform: 'scaleY(0)',
          border: '1px solid',
          borderColor: COLOR.GRAY_LIGHT,
          borderRadius: '6px 6px 0 0',
          transformOrigin: '50% 100%',
          overflow: 'hidden',
          transition: '0.25s all ease-in-out',
          '&.active': {
            opacity: 1,
            transform: 'scaleY(1)',
            transition: '0.25s all ease-in-out',
          },
        }}
      >
        {list.map((item, index) => {
          return (
            <UIButtonNav
              key={generateReactKey()}
              text={item}
              onClick={() => {
                setValue(item)
                setOpen(false)
              }}
              sx={{
                borderRadius: 0,
                borderTop: index > 0 ? '1px solid' : '',
                borderColor: COLOR.GRAY_LIGHT,
              }}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default UIDropdown
