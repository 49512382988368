import { getVideoLink } from '../segments.actions'

export default (builder) => {
  builder
    .addCase(getVideoLink.pending, (state) => {
      state.video.loading = true
    })
    .addCase(getVideoLink.fulfilled, (state, action) => {
      state.video.loading = false
      state.video.error = null
      state.video.url = action.payload
      state.video.playing = true
    })
    .addCase(getVideoLink.rejected, (state, action) => {
      state.video.loading = false
      state.video.error = action.payload
    })
}
