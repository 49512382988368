import React from 'react'
import { Modal, Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { COLOR } from '../../utils/constants/color.constants'

const UiModal = ({ open, setOpen, wrapperStyles, children }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        className="disable-scrollbar"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            md: 650,
            xs: 'calc(100vw - 32px)',
          },
          background: COLOR.GRAY_EXTRA_LIGHT,
          borderRadius: '10px',
          padding: {
            xs: '10px',
            md: '30px',
          },
          maxHeight: '80vh',
          overflow: 'auto',
          ...wrapperStyles,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
          }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <Close
            style={{
              fill: COLOR.RED_ERROR,
            }}
          />
        </IconButton>
        {children}
      </Box>
    </Modal>
  )
}

export default UiModal
