export const getFormattedDate = (dateString) => {
  const date = new Date(dateString)
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
    return 'Unknown date'
  }
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')
  const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
  return formattedDate
}

export const getMinutesFromMilliseconds = (milliseconds) => {
  if (!milliseconds || parseInt(milliseconds, 10) < 0) {
    return 'Unknown'
  }
  return (parseInt(milliseconds, 10) / (1000 * 60)).toFixed(2)
}

export const getFormattedDuration = (milliseconds) => {
  if (typeof milliseconds === 'string') {
    milliseconds = parseInt(milliseconds, 10)
  }

  if (Number.isNaN(milliseconds) || milliseconds < 0) {
    return 'Unknown'
  }

  let seconds = milliseconds / 1000
  let minutes = Math.floor(seconds / 60)
  seconds = (seconds % 60).toFixed(1)
  const hours = Math.floor(minutes / 60)
  minutes %= 60

  let formattedDuration = ''

  if (hours > 0) {
    formattedDuration += `${hours}h `
  }
  if (minutes > 0 || hours > 0) {
    formattedDuration += `${minutes}m `
  }
  formattedDuration += `${seconds}s`

  return formattedDuration.trim()
}

export const removeTimeZoneFromSegmentName = (inputString) => {
  const [prefix, dateTime, suffix] = inputString.split('_')
  let dateWithoutTimeZone = ''
  if (dateTime.includes('+')) {
    dateWithoutTimeZone = dateTime.slice(0, dateTime.indexOf('+'))
  }
  return `${prefix}_${dateWithoutTimeZone}_${suffix}`
}

// TODO: 2 функции ниже - временный костыль, созданный, потому что api присылает имена
//  сегментов по разному. Убрать, если пофиксят api
const removeMinutesFromTimeZone = (inputString) => {
  if (inputString.slice(inputString.length - 3, inputString.length) === ':00') {
    return inputString.slice(0, inputString.length - 3)
  }
  return inputString
}

export const compareSegmentNames = (inputString1, inputString2) => {
  if (inputString1 === inputString2) return true
  const suffix = 'RS'
  const suffixIndex1 = inputString1.indexOf(suffix)
  const suffixIndex2 = inputString2.indexOf(suffix)
  if (suffixIndex1 === -1 || suffixIndex2 === -1) return false
  const dateTimeString1 = removeMinutesFromTimeZone(inputString1.slice(0, suffixIndex1 - 1))
  const dateTimeString2 = removeMinutesFromTimeZone(inputString2.slice(0, suffixIndex2 - 1))
  return dateTimeString1 === dateTimeString2
}
