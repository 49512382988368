import React from 'react'
import { IconButton } from '@mui/material'
import { DeleteRounded } from '@mui/icons-material'
import { COLOR } from '../../../utils/constants/color.constants'

const UIIconButtonDelete = ({ sx, ...props }) => {
  return (
    <IconButton
      sx={{
        borderRadius: '6px',
        '&:hover': {
          '& svg': {
            fill: COLOR.RED_DARK,
          },
        },
        ...sx,
      }}
      {...props}
    >
      <DeleteRounded sx={{ fill: COLOR.GRAY_MEDIUM }} />
    </IconButton>
  )
}

export default UIIconButtonDelete
