export const getPreferenceKey = (key) => {
  const userPreferences = JSON.parse(localStorage.getItem('userPreferences'))
  return userPreferences?.[key] ?? null
}

export const setPreferenceKey = (key, value) => {
  let userPreferences = JSON.parse(localStorage.getItem('userPreferences'))
  if (userPreferences) {
    userPreferences[key] = value
  } else {
    userPreferences = {
      [key]: value,
    }
  }
  localStorage.setItem('userPreferences', JSON.stringify(userPreferences))
}

export const preferenceKeys = {
  CAM_PER_PAGE: 'CAM_PER_PAGE',
  TRIGGERS_PER_PAGE: 'TRIGGERS_PER_PAGE',
  SAVED_SEGMENTS_PER_PAGE: 'SAVED_SEGMENTS_PER_PAGE',
}
