import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { COLOR } from '../../utils/constants/color.constants'

const UIOverlayPreloader = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: COLOR.WHITE_TR_60,
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        sx={{
          fill: COLOR.GRAY_MEDIUM,
          color: COLOR.GRAY_MEDIUM,
        }}
      />
    </Box>
  )
}

export default UIOverlayPreloader
